import { Typography, Row, Col, Result, Pagination, Flex } from 'antd';
import React from 'react';
import { QuestionOutlined } from '@ant-design/icons';
import { useState } from 'react';
import OrganizationCard from '../organization-card/OrganizationCard';
import { FormattedMessage } from 'react-intl';
const OrganizationList = ({ organizationList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;

  const paginatedData = organizationList.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize,
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const { Title } = Typography;
  return (
    <div>
      <Title level={3}>
        <FormattedMessage id='organizations' />
      </Title>
      {organizationList.length === 0 && (
        <Result
          icon={<QuestionOutlined />}
          title='404'
          subTitle={<FormattedMessage id='organisationCouldntBeFound' />}
        />
      )}
      <Row gutter={[8, 8]}>
        {paginatedData.map((item) => (
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} key={item.id}>
            <OrganizationCard
              img={item?.photoUrl}
              id={item?.id}
              fullName={item.fullname}
              userName={item.username}
            />
          </Col>
        ))}
        <Col span={24}>
          <Flex justify='end'>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={organizationList.length}
              onChange={handlePageChange}
            />
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationList;

import React, { useState } from 'react';
import { Row, Col, Flex, Typography, Pagination, Result } from 'antd';
import UserCard from '../user-card/UserCard';
import { QuestionOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const UserList = ({ userList }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;

  const paginatedData = userList.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const { Title } = Typography;
  return (
    <div>
      <Title id='userList' level={3}>
        <FormattedMessage id='users' />
      </Title>
      {userList.length === 0 && (
        <Result
          icon={<QuestionOutlined />}
          title='404'
          subTitle={<FormattedMessage id='usersCouldntBeFound' />}
        />
      )}
      <Row gutter={[8, 8]}>
        {paginatedData.map((item) => (
          <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12} key={item.id}>
            <UserCard
              img={item?.photoUrl}
              id={item?.id}
              fullName={item.fullname}
              userName={item.username}
            />
          </Col>
        ))}
        <Col span={24}>
          <Flex justify='end'>
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              total={userList.length}
              onChange={handlePageChange}
            />
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default UserList;

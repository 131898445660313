import { useContext } from 'react';
import { Button, Divider, Flex, Radio } from 'antd';
import { useIntl } from 'react-intl';
import SteamRow from '../integrations/steam/SteamRow';
import SteamGenreRow from '../integrations/steam/SteamGenreRow';
import LolGamePage from './lol-statics/LolGamePage';
import {
  useGetLastSteamActivity,
  useGetMutualSteamGames,
  useGetUserSteamGenres,
  useGetUserSteamLibrary,
} from '../../hooks/queries/IntegrationQueries';
import { UtilityContext } from '../../providers/UtilityProvider';

const SteamStatistics = ({
  userId,
  isOwner,
  genresLoading,
  userSteamGenres,
  steamActivity,
  steamActivityLoading,
  data,
  isLoading,
  mutualGames,
  mutualGamesLoading,
}) => {
  const translator = useIntl();

  return (
    <>
      <SteamGenreRow userId={userId} isLoading={genresLoading} steamGenres={userSteamGenres} />
      <Divider />
      <SteamRow
        title={translator.formatMessage({ id: 'lastPlayed' })}
        lastActivity={true}
        userId={userId}
        steamLibrary={steamActivity}
        isLoading={steamActivityLoading}
        isOwner={isOwner}
      />
      <Divider />
      <SteamRow userId={userId} steamLibrary={data} isLoading={isLoading} isOwner={isOwner} />
      <Divider />
      {!isOwner && (
        <SteamRow
          mutual={true}
          title={translator.formatMessage({ id: 'mutualGames' })}
          userId={userId}
          steamLibrary={mutualGames}
          isLoading={mutualGamesLoading}
          isOwner={isOwner}
        />
      )}
    </>
  );
};

const UserGameStatistics = ({ userId, isOwner, lolStats, profileDetails }) => {
  const { data, isLoading } = useGetUserSteamLibrary(userId);
  const { data: steamActivity = [], isLoading: steamActivityLoading } =
    useGetLastSteamActivity(userId);
  const { data: mutualGames = [], isLoading: mutualGamesLoading } = useGetMutualSteamGames(userId);
  const { data: userSteamGenres = [], isLoading: genresLoading } = useGetUserSteamGenres(userId);
  const { gameStaticsTab, setGameStaticsTab } = useContext(UtilityContext);
  const translator = useIntl();

  const onChange = (e) => setGameStaticsTab(e.target.value);

  return (
    <Flex vertical={true} gap='0.75rem'>
      <Flex justify='end'>
        <Radio.Group onChange={onChange} defaultValue={gameStaticsTab}>
          <Radio.Button value='steam'>Steam</Radio.Button>
          {lolStats && <Radio.Button value='leagueOfLegend'>League of Legends</Radio.Button>}
        </Radio.Group>
      </Flex>
      {gameStaticsTab === 'steam' ? (
        <SteamStatistics
          userId={userId}
          isOwner={isOwner}
          genresLoading={genresLoading}
          userSteamGenres={userSteamGenres}
          steamActivity={steamActivity}
          steamActivityLoading={steamActivityLoading}
          data={data}
          isLoading={isLoading}
          mutualGames={mutualGames}
          mutualGamesLoading={mutualGamesLoading}
        />
      ) : (
        <LolGamePage profileDetails={profileDetails} lolStats={lolStats} />
      )}
    </Flex>
  );
};

export default UserGameStatistics;

import { riotIntegrateUrl, steamIntegrateUrl } from '../../../helpers/constants/constantHelpers';
import { Button, Flex, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import RiotGamesSVG from '../../../assets/svg/RiotGamesSVG';
import { useContext, useEffect } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { SyncOutlined } from '@ant-design/icons';
import { useSyncLoL } from '../../../hooks/queries/IntegrationQueries';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';

const { Text } = Typography;

const RiotGamesLibrary = ({ lolStats, profileDetails }) => {
  const { openNotification } = useContext(UtilityContext);
  const { width } = useWindowSize();
  const { theme } = useContext(UtilityContext);

  const {
    mutateAsync: syncRiotHelper,
    isPending,
    isSuccess,
  } = useSyncLoL(lolStats?.summoner?.puuid, lolStats?.summoner?.cpid, profileDetails?.id);

  useEffect(() => {
    if (isSuccess) {
      openNotification('riotSynced', 'success');
    }
  }, [isSuccess]);

  if (isPending) {
    return <SpinWrapper />;
  }

  if (lolStats) {
    return (
      <Button
        className='block mr-auto'
        onClick={syncRiotHelper}
        loading={isPending}
        type='primary'
        icon={<SyncOutlined className='mr-2' />}
      >
        {width > 900 ? (
          <Text className='text-inherit'>
            <FormattedMessage id='sync' />
          </Text>
        ) : (
          ''
        )}
      </Button>
    );
  } else {
    return (
      <div>
        <Link to={riotIntegrateUrl()}>
          <Button type='primary' icon={<RiotGamesSVG dark={theme} width={20} height={20} />}>
            <Text className='text-inherit'>
              {width > 900 ? <FormattedMessage id='integrate' /> : ''}
            </Text>
          </Button>
        </Link>
      </div>
    );
  }
};

export default RiotGamesLibrary;

import React, { useContext } from 'react';
import PrimaryCard from '../../cards/PrimaryCard';
import { Link } from 'react-router-dom';
import { Flex, Image, Typography, Button } from 'antd';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import { useGetOrganizationFollowers } from '../../../hooks/queries/OrganizationQueries';
import { UserOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../providers/AuthProvider';
import { useFollowUser } from '../../../hooks/queries/UserQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { FormattedMessage } from 'react-intl';
const OrganizationCard = ({ id, img, fullName, userName }) => {
  const { user } = useContext(AuthContext);
  const { Text } = Typography;
  const { openNotification } = useContext(UtilityContext);
  const { data: followers, isLoading } = useGetOrganizationFollowers(id);
  const { mutateAsync: followUser } = useFollowUser(id);

  const followHandler = (id) => {
    let isFollowing = followers.includes(user?.id);
    followUser({
      userId: id,
      follower_user_id: user?.id,
    }).then((response) => {
      if (response.status === 202) {
        if (isFollowing) {
          return openNotification('userFollowStopped', 'success');
        }
        return openNotification('userFollowed', 'success');
      }
    });
  };
  return (
    <PrimaryCard>
      <Link className='w-full' to={`/organization/${userName}`}>
        <Flex className='flex-col w-full' gap='0.75rem' justify='center' align='center'>
          <Image
            className='rounded-full'
            preview={false}
            width={58}
            height={58}
            src={img ? img : defaultProfilePhoto}
          />
          <Flex align='center' gap='0.25rem'>
            <UserOutlined className='text-sm' />{' '}
            <Text className='text-xs'>
              {followers?.length} <FormattedMessage id='numberOfFollowers' />
            </Text>
          </Flex>
          <Flex className='flex-col w-full' justify='center' align='center'>
            <Text>{fullName}</Text>
            <Text className='text-sm dark:!text-dark-mode-primary-color'>{userName}</Text>
          </Flex>
        </Flex>
      </Link>
      <Flex className='w-full mt-2' align='center' justify='center' gap='0.75rem'>
        <Flex
          onClick={() => {
            followHandler(id);
          }}
        >
          {followers?.includes(user?.id) ? (
            <Button loading={isLoading} icon={<MinusOutlined />}>
              <FormattedMessage id='unFollow' />
            </Button>
          ) : (
            <Button loading={isLoading} icon={<PlusOutlined />}>
              <FormattedMessage id='follow' />
            </Button>
          )}
        </Flex>
      </Flex>
    </PrimaryCard>
  );
};

export default OrganizationCard;

import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Image, Typography, Flex, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import LeagueRanks from './LeagueRanks';
import useWindowSize from '../../../hooks/useWindowSize';
import ChampionsWinRate from './ChampionsWinRate';
import { SyncOutlined } from '@ant-design/icons';
import { useSyncLoL } from '../../../hooks/queries/IntegrationQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';

const LolGamePage = ({ lolStats, profileDetails }) => {
  const { width } = useWindowSize();
  const { Title, Text } = Typography;
  const { openNotification } = useContext(UtilityContext);

  const [championData, setChampionData] = useState([]);
  const [championDetail, setChampionDetail] = useState([]);

  const { mutateAsync: syncRiotHelper, isSuccess } = useSyncLoL(
    lolStats?.summoner?.puuid,
    lolStats?.summoner?.cpid,
    profileDetails?.id,
  );

  useEffect(() => {
    if (isSuccess) {
      openNotification('riotSynced', 'success');
    }
  }, [isSuccess]);

  const championDataValues = Object.values(championData.data || {});
  const mainChampion = championDetail?.length > 0 && championDetail[0]?.id;

  useEffect(() => {
    fetch('/champion.json')
      .then((response) => response.json())
      .then((data) => setChampionData(data));
  }, []);

  useEffect(() => {
    const newData = lolStats?.champion_stats?.map((item) => {
      const compareItem = championDataValues?.find((champ) => champ.key == item.champion_id);
      return compareItem ? { ...item, ...compareItem } : null;
    });
    setChampionDetail(newData);
  }, [lolStats?.champion_stats, championData]);

  const calculateMatches = (queueType) => {
    return lolStats?.ranked_stats?.reduce((total, item) => {
      return item?.queue_type === queueType ? total + item?.wins + item?.losses : total;
    }, 0);
  };

  const calculateWins = (queueType) => {
    return lolStats?.ranked_stats?.reduce((total, item) => {
      return item?.queue_type === queueType ? total + item.wins : total;
    }, 0);
  };

  /*const allWin = calculateWins();*/
  const soloQWin = calculateWins('RANKED_SOLO_5x5');
  const flexQWin = calculateWins('RANKED_FLEX_SR');

  const soloQMathces = calculateMatches('RANKED_SOLO_5x5');
  const flexMatches = calculateMatches('RANKED_FLEX_SR');

  /*const allWinrate = ((100 * allWin) / allMatches).toFixed(1);*/
  const soloQWinRate = ((100 * soloQWin) / soloQMathces).toFixed(1);
  const flexWinRate = ((100 * flexQWin) / flexMatches).toFixed(1);

  const soloInformation = lolStats?.ranked_stats.find((i) => i.queue_type === 'RANKED_SOLO_5x5');
  const flexInformation = lolStats?.ranked_stats.find((i) => i.queue_type === 'RANKED_FLEX_SR');

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
          <div
            className={`relative bg-no-repeat bg-cover shadow-2xl rounded-[10px] ${
              width < 1000 ? 'w-[93vw] h-[480px] bg-center' : 'h-[560px]'
            }`}
            style={{
              backgroundImage: `url(https://ddragon.leagueoflegends.com/cdn/img/champion/${
                width < 1000 ? 'splash' : 'loading'
              }/${mainChampion}_0.jpg)`,
            }}
          >
            <Button
              className='absolute top-4 right-4'
              icon={<SyncOutlined />}
              onClick={syncRiotHelper}
            />
            <Flex
              vertical={true}
              align='center'
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[85%] p-4 rounded-[8px] bg-[#ffffff80]'
            >
              <Image
                preview={false}
                width={90}
                className='rounded-[8px]'
                src={`https://ddragon.leagueoflegends.com/cdn/14.22.1/img/profileicon/${lolStats?.summoner?.profile_icon_id}.png`}
              />
              <div className='flex flex-col items-center w-full mb-4'>
                <Title className='text-center !mb-0 text-white' level={4}>
                  {lolStats?.summoner?.game_name}#{lolStats?.summoner?.tag_line}
                </Title>
                <Text className='text-center text-xl'>
                  <FormattedMessage id='level' />: {lolStats?.summoner?.summoner_level}
                </Text>
              </div>
              {soloQWinRate !== 'NaN' && (
                <LeagueRanks title='solo' winRate={soloQWinRate} rankedStats={soloInformation} />
              )}
              {flexWinRate !== 'NaN' && (
                <LeagueRanks title='flex' winRate={flexWinRate} rankedStats={flexInformation} />
              )}
            </Flex>
          </div>
        </Col>
        <Col xxl={18} xl={18} lg={18} md={24} sm={24} xs={24}>
          <ChampionsWinRate championDetail={championDetail} />
        </Col>
      </Row>
    </div>
  );
};

export default LolGamePage;

import React, { useContext } from 'react';
import { Flex, Image, Skeleton, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useGetOrganizationDetailByIdHandler } from '../../hooks/queries/OrganizationQueries';
import { AuthContext } from '../../providers/AuthProvider';
import defaultOrganizationPhoto from '../../assets/images/defaultOrganizationPhoto.png';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const InviteOrganizationNotification = ({ notification }) => {
  const { user } = useContext(AuthContext);
  const { data: organizationDetails, isLoading } = useGetOrganizationDetailByIdHandler(
    parseInt(notification.related_parameter),
  );
  const Text = Typography;
  const getLanguageBrowser = user?.language ?? navigator.language;
  let timeStamp = dateFormatter(notification.created_at);

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <div className='w-full margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/organization/${organizationDetails?.code}`}>
          <Image
            className='rounded-full object-cover'
            width={40}
            height={40}
            preview={false}
            placeholder={<Skeleton avatar active />}
            src={
              organizationDetails?.logo_url ? organizationDetails?.logo_url : defaultOrganizationPhoto
            }
          />
        </Link>
        <Link to={`/organization/${organizationDetails?.code}`}>
          <Text>
            {getLanguageBrowser === 'en' && (
              <>
                <FormattedMessage id='inviteOrganizationNotification' />{' '}
              </>
            )}
            {organizationDetails?.name ? organizationDetails?.name : <FormattedMessage id='deletedOrganization' /> }
            {getLanguageBrowser === 'tr' && (
              <>
                {' '}<FormattedMessage id='inviteOrganizationNotification' />
              </>
            )}
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default InviteOrganizationNotification;

import { Button, Flex, Image } from 'antd';
import { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../../style/layout.module.scss';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import RiotGamesSVG from '../../../assets/svg/RiotGamesSVG';
import { UtilityContext } from '../../../providers/UtilityProvider';
import SteamIntegrationTab from './SteamIntegrationTab';
import RiotGamesIntegrationTab from './RiotGamesIntegrationTab';

const IntegrationsHolder = ({ profileDetails, isOwner, showMoreSteam }) => {
  const { theme } = useContext(UtilityContext);
  const [selectedLibrary, setSelectedLibrary] = useState(1);

  const libraryItems = [
    {
      icon: (
        <FontAwesomeIcon
          className={`${
            selectedLibrary === 1 && 'text-white'
          } text-[20px] text-[#192b3c] dark:text-white`}
          icon={faSteam}
        />
      ),
      value: 1,
    },
    {
      icon: <RiotGamesSVG dark={theme} width={20} height={20} />,
      value: 2,
    },
  ];

  return (
    <div>
      <Flex className='mb-4'>
        {libraryItems.map(({ icon, value }) => (
          <Button
            ghost={selectedLibrary !== value}
            type={selectedLibrary === value ? 'primary' : 'default'}
            key={value}
            className='text-xl ease-in duration-200 rounded-full cursor-pointer border-none'
            icon={icon ? icon : null}
            onClick={() => {
              setSelectedLibrary(value);
            }}
          />
        ))}
      </Flex>
      {selectedLibrary === 1 && (
        <SteamIntegrationTab
          profileDetails={profileDetails}
          isOwner={isOwner}
          showMoreSteam={showMoreSteam}
        />
      )}
      {selectedLibrary === 2 && (
        <RiotGamesIntegrationTab isOwner={isOwner} showMoreSteam={showMoreSteam} />
      )}
    </div>
  );
};

export default IntegrationsHolder;

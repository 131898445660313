import { Col, Flex, Input, Row, Typography } from 'antd';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useContext, useEffect, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import SteamAchievements from './SteamAchievements';
import { DrawerProviderContext } from '../../../providers/DrawerProvider';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';
import GameCard from '../../cards/GameCard';
import { getHoursAndMinutes } from '../../../helpers/utils/DateFormatter';
import useWindowSize from '../../../hooks/useWindowSize';

const { Title, Text } = Typography;

const SteamRow = ({
  steamLibrary = [],
  isLoading,
  title = <FormattedMessage id='steamLibrary' />,
  isOwner,
  userId,
  lastActivity = false,
  mutual = false,
}) => {
  const { width } = useWindowSize();
  const { openDrawer } = useContext(DrawerProviderContext);
  const steamRow = useRef();
  const translator = useIntl();

  const [steamRowFilterInput, setSteamRowFilterInput] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (steamRowFilterInput) {
      const filtered = steamLibrary?.filter((game) =>
        game.steamapp?.name.toLocaleLowerCase().includes(steamRowFilterInput.toLocaleLowerCase()),
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(steamLibrary);
    }
  }, [steamRowFilterInput, steamLibrary]);

  const leftScroller = (area) => {
    if (area === 'steam') {
      const width = steamRow.current?.offsetWidth;

      steamRow.current?.scrollTo({
        left: steamRow.current?.scrollLeft - width,
        behavior: 'smooth',
      });
    }
  };

  const rightScroller = (area) => {
    if (area === 'steam') {
      const width = steamRow.current?.offsetWidth;

      steamRow.current?.scrollTo({
        left: steamRow.current?.scrollLeft + width,
        behavior: 'smooth',
      });
    }
  };

  const achievementDrawerHandler = (steamApp) => {
    openDrawer({
      title: translator.formatMessage({ id: 'achievements' }),
      content: (
        <SteamAchievements
          name={steamApp?.name}
          userId={userId}
          isOwner={isOwner}
          image={steamApp.header_image}
          id={steamApp.app_id}
        />
      ),
      drawerProps: {},
      footer: null,
    });
  };

  const totalTimePlayed = () => {
    if (steamLibrary?.length > 0) {
      const totalTimePlayed = steamLibrary?.reduce((accumulator, currentValue) => {
        return lastActivity
          ? accumulator + currentValue.playtime_2weeks
          : accumulator + currentValue.time_played;
      }, 0);
      if (totalTimePlayed > 0) {
        return `(${getHoursAndMinutes(totalTimePlayed, true)})`;
      } else {
        return '';
      }
    }
    return '-';
  };

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <>
      <Flex wrap={true} className='w-full' justify='space-between' align='center'>
        <div>
          <Title className='!mb-0' level={width > 500 ? 4 : 5}>
            <FontAwesomeIcon className='mr-2 text-[18px]' icon={faSteam} />
            {title} {steamLibrary?.length > 0 && !mutual && <Text>{totalTimePlayed()}</Text>}
          </Title>
        </div>
        <>
          {steamLibrary?.length > 0 && (
            <Flex gap='1rem'>
              <LeftCircleOutlined
                onClick={() => leftScroller('steam')}
                className='text-[18px] cursor-pointer'
              />
              <RightCircleOutlined
                onClick={() => rightScroller('steam')}
                className='text-[18px] cursor-pointer'
              />
            </Flex>
          )}
        </>
      </Flex>
      {!lastActivity && (
        <Input
          className='max-w-[340px] mb-4'
          value={steamRowFilterInput}
          onChange={(event) => setSteamRowFilterInput(event.target.value)}
          placeholder={translator.formatMessage({ id: 'search' })}
        />
      )}
      <Row
        ref={steamRow}
        gutter={[16, 16]}
        className='md:!mx-0 !mx-auto overflow-auto flex-nowrap no-scrollbar max-w-game-statistics-width'
      >
        {lastActivity && steamLibrary?.length === 0 ? (
          <Col span={24}>
            <Text>
              <FormattedMessage id='noGameTimeLastWeeks' />
            </Text>
          </Col>
        ) : (
          filteredData?.map(({ steamapp, time_played, playtime_2weeks }, index) => {
            return (
              <Col xxl={6} xl={6} lg={6} md={8} sm={12} xs={24} key={index}>
                <GameCard
                  key={steamapp?.id}
                  mutual={mutual}
                  genres={steamapp?.steamappgenres}
                  gameTime={time_played ? time_played : playtime_2weeks}
                  url={steamapp?.header_image}
                  showAchievements={() => achievementDrawerHandler(steamapp)}
                />
              </Col>
            );
          })
        )}
      </Row>
    </>
  );
};

export default SteamRow;

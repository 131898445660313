import styles from '../style/postpage.module.scss';
import { Row, Col, Divider } from 'antd';
import InterestedGames from '../components/feed/InterestedGames';
import { useEffect, useState } from 'react';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import { useParams } from 'react-router-dom';
import Navigation from '../components/search/serach-navigation/Navigation';
import UserList from '../components/search/user-list/UserList';
import PostList from '../components/search/post-list/PostList';
import OrganizationList from '../components/search/organization-list/OrganizationList';
import { getSearchedPage } from '../requests/UtilityManager';

const SearchingPage = () => {
  const { hashtag } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);

  useEffect(() => {
    if (hashtag?.length > 3) {
      setIsLoading(true);
      getSearchedPage(hashtag)
        .then((response) => {
          if (response.status === 202) {
            const usersData = response.data.data.filter((item) => item.type === 1);
            const organizationsData = response.data.data.filter((item) => item.type === 2);
            setUserList(usersData);
            setOrganizationList(organizationsData);
          } else {
            setUserList([]);
            setOrganizationList([]);
          }
        })
        .finally(() => setIsLoading(false));
    } else {
      setUserList([]);
      setOrganizationList([]);
      setIsLoading(false);
    }
  }, [hashtag]);

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row className={styles['postPageWrapper']} gutter={[16, 16]}>
      <Col xxl={5} xl={5} lg={5} md={5} sm={24} xs={24}>
        <Row className={styles['stickyRow']}>
          <Col span={24}>
            <InterestedGames />
          </Col>
        </Row>
      </Col>
      <Col xxl={13} xl={13} lg={13} md={13}>
        <Row justify='center'>
          {userList.length > 0 ? (
            <Col span={24}>
              <UserList userList={userList} />
              <Divider />
            </Col>
          ) : null}
          {organizationList.length > 0 ? (
            <Col span={24}>
              <OrganizationList organizationList={organizationList} />
              <Divider />
            </Col>
          ) : null}
          <Col span={24}>
            <PostList hashtag={hashtag} />
          </Col>
        </Row>
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={0} xs={0}>
        <Row className={styles['stickyRow']}>
          <Navigation />
        </Row>
      </Col>
    </Row>
  );
};

export default SearchingPage;

import React, { useContext, useEffect, useState } from 'react';
import { SelectComponent } from '../filters/Inputs';
import { Button, Col, Row, Checkbox, Flex, Form, Input, Modal, Table, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  RobotOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import {
  useCreateOrganizationMember,
  useDeleteOrganizationMember,
  useGetOrganizationMembers,
  useGetOrganizationMembersDetail,
  useGetOrganizationFollowersDetail,
  useGetOrganizationFollowers,
  useUpdateOrganization,
} from '../../../hooks/queries/OrganizationQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import { DrawerProviderContext } from '../../../providers/DrawerProvider';

const { Text, Title, Paragraph } = Typography;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td key={record?.id} {...restProps}>
      {editing ? (
        <Form.Item
          valuePropName={inputType === 'text' ? 'value' : 'checked'}
          name={inputType === 'text' ? 'role' : 'isManager'}
          style={{
            margin: 0,
          }}
          initialValue={inputType === 'text' ? record?.role : record?.isManager}
          rules={[
            {
              required: inputType === 'text',
              message: <FormattedMessage id='fieldRequired' />,
            },
          ]}
        >
          {inputType === 'text' ? (
            <Input />
          ) : (
            <Checkbox>
              <FormattedMessage id='isAdmin' />
            </Checkbox>
          )}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ManageOrganizationMembers = ({ organization }) => {
  const [form] = Form.useForm();
  const [organizationMembersForm] = Form.useForm();
  const [organizationAbout] = Form.useForm();
  const [editingMember, setEditingMember] = useState('');
  const [deletingMember, setDeletingMember] = useState('');
  const { openNotification, theme } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const { closeDrawer } = useContext(DrawerProviderContext);

  const { data: followers } = useGetOrganizationFollowers(organization?.id);
  const { data: memberIds } = useGetOrganizationMembers(organization?.id);
  const { data: memberDetails } = useGetOrganizationMembersDetail(memberIds, organization?.id);
  const nonFounderDetails = memberDetails?.filter(
    (member) => member.id !== organization?.founder_id,
  );
  const { data: followerDetails = [] } = useGetOrganizationFollowersDetail(
    followers?.filter((id) => !memberIds.includes(id)),
    organization?.id,
  );
  const { mutate: editMember, isError, isSuccess } = useCreateOrganizationMember(organization?.id);
  const {
    mutate: createMember,
    isPending,
    isSuccess: createSuccess,
  } = useCreateOrganizationMember(organization?.id);
  const {
    mutate: deleteMember,
    isError: deleteError,
    isSuccess: deleteSuccess,
  } = useDeleteOrganizationMember(organization?.id);
  const { mutate: updateOrganization } = useUpdateOrganization(organization?.code);

  const translator = useIntl();

  const isEditing = (record) => record.id === editingMember.id;
  const [loading, setLoading] = useState(false);
  const [transferModalStatus, setTransferModalStatus] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const organizationFounderEditHandler = (formValues) => {
    setLoading(true);

    if (formValues?.newFounder_id) {
      formValues['founder_id'] = formValues?.newFounder_id;
    }

    formValues['id'] = organization?.id;
    formValues['userId'] = user?.id;
    updateOrganization(formValues);
    setLoading(false);
    setTransferModalStatus(false);
    closeDrawer();
  };

  const submitMembers = (formValues) => {
    formValues['isMember'] = true;
    if (formValues.userIds?.length > 0 && formValues.role?.replaceAll(' ', '').length > 0) {
      createMember(formValues);
      organizationMembersForm.resetFields();
    }
    if (formValues.userIds?.length === 0 || formValues.role?.replaceAll(' ', '').length === 0) {
      openNotification('memberAddFailed', 'error');
    }
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const edit = (record) => {
    form.setFieldsValue({
      role: record.role,
      isManager: record.type === 1,
    });
    record['isManager'] = record.type === 1;
    setEditingMember(record);
  };

  const cancel = () => {
    setEditingMember('');
  };

  const save = () => {
    let formValues = { ...form.getFieldsValue(), userIds: [editingMember.id], isMember: true };
    if (
      formValues['role'] !== editingMember.role ||
      formValues['isManager'] !== editingMember.isManager
    ) {
      editMember(formValues);
    }
    setEditingMember('');
  };

  const deleteMemberHandler = () => {
    let formObject = {
      userIds: [deletingMember.id],
      organizationId: organization?.id,
      role: deletingMember.role,
    };
    deleteMember(formObject);
    setDeletingMember('');
    organizationAbout.resetFields();
  };

  const deleteHandler = (record) => {
    setDeletingMember(record);
  };

  useEffect(() => {
    if (isSuccess) {
      openNotification('updateSuccess', 'success');
    }
    if (isError) {
      openNotification('updateFailed', 'error');
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    if (deleteSuccess) {
      openNotification('memberDeleteSuccess', 'success');
    }
    if (deleteError) {
      openNotification('unknownError', 'error');
    }
  }, [deleteError, deleteSuccess]);

  useEffect(() => {
    if (createSuccess) {
      openNotification('memberAdded', 'success');
    }
  }, [createSuccess]);

  const columns = [
    {
      title: translator.formatMessage({ id: 'name' }),
      dataIndex: 'fullname',
      width: '50%',
      editable: false,
      render: (record) => {
        return <Text> {record}</Text>;
      },
    },
    {
      title: translator.formatMessage({ id: 'role' }),
      dataIndex: 'role',
      width: '30%',
      editable: true,
      render: (record) => {
        return <Text> {record}</Text>;
      },
    },
    {
      title: translator.formatMessage({ id: 'admin' }),
      width: '20%',
      dataIndex: 'type',
      editable: true,
      render: (type, record) =>
        type ? (
          record.id === organization?.founder_id ? (
            <RobotOutlined className='dark:text-dark-mode-primary-color text-primary'/>
          ) : (
            <CheckOutlined className='text-green-600' />
          )
        ) : (
          <CloseOutlined className='text-red-600' />
        ),
    },
    {
      title: translator.formatMessage({ id: 'actions' }),
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Flex justify='space-between'>
            <Typography.Link onClick={() => save(record.id)}>
              <SaveOutlined />
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              <CloseOutlined />
            </Typography.Link>
          </Flex>
        ) : (
          <Flex justify='space-between'>

            {record.id !== organization?.founder_id && (
                <>
                  <Typography.Link disabled={editingMember !== ''} onClick={() => edit(record)}>
                    <EditOutlined className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full transition-all dark:hover:bg-yellow-600 dark:hover:text-white' />
                  </Typography.Link>
                  <Typography.Link
                    disabled={editingMember !== ''}
                    onClick={() => deleteHandler(record)}
                  >
                    <DeleteOutlined className='cursor-pointer text-red-600 hover:bg-red-200 p-2 rounded-full transition-all dark:hover:bg-red-600 dark:hover:text-white' />
                  </Typography.Link>
                </>
            )}
          </Flex>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'type' ? 'boolean' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      <Title level={5}> {translator.formatMessage({ id: 'addMembers' })} </Title>
      <Form
        initialValues={{
          userIds: [],
          role: '',
          isAdmin: false,
        }}
        layout='vertical'
        onFinish={submitMembers}
        form={organizationMembersForm}
      >
        <Row className='flex justify-between' gutter={[16, 2]}>
          <Col xs={24} md={12}>
            <SelectComponent
              options={followerDetails}
              formName='userIds'
              multi={true}
              translationId='chooseMembers'
              fieldNames={{
                label: 'fullname',
                value: 'id',
              }}
              filterOption={(input, option) =>
                (option.fullname.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
            />
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name='role'>
              <Input
                className='text-black'
                placeholder={translator.formatMessage({ id: 'enterUserRole' })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className='flex justify-between' gutter={[16, 2]}>
          <Col>
            <Form.Item valuePropName='checked' name='isManager'>
              <Checkbox>
                <FormattedMessage id='isAdmin' />
              </Checkbox>
            </Form.Item>
          </Col>
          <Col>
            <Button loading={isPending} htmlType='submit' type='primary'>
              <Text className='text-inherit'>
                <FormattedMessage id='save' />
              </Text>
            </Button>
          </Col>
        </Row>
      </Form>
      <Title level={5} className='mt-6'>
        {' '}
        {translator.formatMessage({ id: 'manageMembers' })}{' '}
      </Title>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          rowKey={'id'}
          dataSource={memberDetails}
          columns={mergedColumns}
          rowClassName='editable-row'
          pagination={{
            onChange: cancel,
          }}
          scroll={{ x: 'maximum-content' }}
        />
        <Modal
          closeIcon={<CloseOutlined />}
          title={translator.formatMessage({ id: 'memberDeleteWarn' })}
          okText={translator.formatMessage({ id: 'ok' })}
          onCancel={() => setDeletingMember('')}
          onOk={deleteMemberHandler}
          cancelText={translator.formatMessage({ id: 'cancel' })}
          cancelButtonProps={{ icon: <CloseCircleFilled style={{ color: '#D61C1C' }} /> }}
          okButtonProps={{ icon: <CheckCircleFilled style={{ color: '#fff' }} /> }}
          open={deletingMember?.id}
          destroyOnClose={true}
          centered={true}
        >
          <FormattedMessage id='memberRemoveMessage' />
        </Modal>
      </Form>
      <Title level={5} className='mt-6'>
        {' '}
        {translator.formatMessage({ id: 'transferOrganization' })}{' '}
      </Title>
      <Form
        layout='vertical'
        initialValues={{
          ...organization,
        }}
        onFinish={organizationFounderEditHandler}
        form={organizationAbout}
      >
        <Row gutter={[16, 2]}>
          <Col xs={24} md={15}>
            <SelectComponent
              options={nonFounderDetails}
              formName='newFounder_id'
              translationId='newFounder'
              fieldNames={{
                label: 'fullname',
                value: 'id',
              }}
              filterOption={(input, option) =>
                (option.fullname.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
              required={true}
              onChangeRemote={handleSelectChange}
            />
          </Col>
          <Col xs={24} md={8}>
            <Form.Item>
              <Button
                loading={loading}
                icon={<RobotOutlined />}
                type='primary'
                onClick={() => setTransferModalStatus(true)}
                disabled={!selectedValue}
                block
              >
                <Text className='text-inherit'>
                  <FormattedMessage id='transferOrganization' />
                </Text>
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        closeIcon={<CloseOutlined />}
        destroyOnClose={true}
        title={translator.formatMessage({ id: 'attention' })}
        open={transferModalStatus}
        onCancel={() => setTransferModalStatus(false)}
        onOk={() => organizationAbout.submit()}
        okText={translator.formatMessage({ id: 'approve' })}
        okButtonProps={{
          loading: loading,
        }}
        cancelButtonProps={{
          danger: true,
        }}
      >
        <Paragraph>
          <FormattedMessage id='transferOrganizationWarning' />
        </Paragraph>
      </Modal>
      <Col span={24}>
        <Text italic type='secondary'>
          <FormattedMessage id='organizationTransferDescription' />
        </Text>
      </Col>
    </>
  );
};
export default ManageOrganizationMembers;

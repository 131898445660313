import { useEffect, useRef, useState } from 'react';
import { Skeleton } from 'antd';

const LazyImage = ({ src, alt, className, onError }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const imgRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 },
    );

    if (imgRef.current) observer.observe(imgRef.current);

    return () => {
      if (imgRef.current) observer.unobserve(imgRef.current);
    };
  }, []);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div ref={imgRef} className='relative'>
      {isLoading && <Skeleton.Image active className='!w-full !h-[160px] rounded-[8px]' />}
      {isVisible && (
        <img
          src={src}
          alt={alt}
          className={`${className} ${isLoading ? 'hidden' : 'block rounded-[8x]'}`}
          onLoad={handleImageLoad}
          onError={onError}
        />
      )}
    </div>
  );
};

export default LazyImage;

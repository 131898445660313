import { AutoComplete, Button, Flex, Form, Image, Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getSearchedPage } from '../../requests/UtilityManager';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { faUser, faUsers } from '@fortawesome/free-solid-svg-icons';

const { Text } = Typography;

const MainSearch = () => {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const translator = useIntl();
  const navigate = useNavigate();
  const [searchForm] = Form.useForm();

  const navigateHandler = (text) => {
    const foundOption = options.find((item) => item.value === text);
    if (foundOption.type === 1) {
      navigate(`/profile/${foundOption.value}`);
    } else {
      navigate(`/organization/${foundOption.value}`);
    }
    searchForm.resetFields();
  };

  const autoCompleteOptionsHandler = (text) => {
    setSelectedValue(text);
    if (text.length > 3) {
      getSearchedPage(text).then((response) => {
        if (response.status === 202) {
          let options = [];
          for (let data of response.data.data) {
            let dataObject = {
              label: (
                <Flex gap={10}>
                  <Image
                    style={{ borderRadius: 6 }}
                    width={35}
                    height={30}
                    src={data.photoUrl ? data.photoUrl : defaultProfilePhoto}
                  />
                  <Text>
                    {data.fullname}
                    <span className='ml-2'>
                      {data.type === 2 ? (
                        <FontAwesomeIcon icon={faUsers} className='text-gray' />
                      ) : (
                        <FontAwesomeIcon icon={faUser} className='text-gray' />
                      )}
                    </span>
                  </Text>
                </Flex>
              ),
              value: data.username,
              key: data.id,
              type: data.type,
            };
            options.push(dataObject);
          }

          setOptions(options);
        } else {
          setOptions([]);
        }
      });
    } else {
      setOptions([]);
    }
  };

  const handleSearch = (formValues) => {
    if (formValues?.hash?.replaceAll(' ', '').length > 0) {
      let hash = formValues.hash.replaceAll('#', '$');
      navigate(`/hash/${hash}`);
    }
  };

  return (
    <Form form={searchForm} className='flex w-full' onFinish={handleSearch}>
      <Form.Item className='w-full !m-0' name='hash'>
        <AutoComplete
          options={options}
          style={{
            width: '100%',
          }}
          onSelect={navigateHandler}
          onChange={autoCompleteOptionsHandler}
          placeholder={
            <Text>
              <FormattedMessage id='mainSearch' />
            </Text>
          }
        />
      </Form.Item>
      <Button className='ml-4' htmlType='submit' icon={<SearchOutlined />} />
    </Form>
  );
};

export default MainSearch;

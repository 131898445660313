import truncate from 'html-truncate';
import { useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faTag,
  faUsers,
  faDiceD20,
  faCompass,
  faChess,
  faLightbulb,
  faHourglassStart,
  faCogs,
  faSmile,
  faFilm,
  faPaintBrush,
  faCamera,
  faTools,
  faFlagCheckered,
  faFootballBall,
  faGamepad,
} from '@fortawesome/free-solid-svg-icons';

import IRON from '../../assets/images/Ranked Emblems Latest/Rank=Iron.png';
import BRONZE from '../../assets/images/Ranked Emblems Latest/Rank=Bronze.png';
import SILVER from '../../assets/images/Ranked Emblems Latest/Rank=Silver.png';
import GOLD from '../../assets/images/Ranked Emblems Latest/Rank=Gold.png';
import PLATINUM from '../../assets/images/Ranked Emblems Latest/Rank=Platinum.png';
import EMERALD from '../../assets/images/Ranked Emblems Latest/Rank=Emerald.png';
import DIAMOND from '../../assets/images/Ranked Emblems Latest/Rank=Diamond.png';
import MASTER from '../../assets/images/Ranked Emblems Latest/Rank=Master.png';
import GRANDMASTER from '../../assets/images/Ranked Emblems Latest/Rank=Grandmaster.png';
import CHALLENGER from '../../assets/images/Ranked Emblems Latest/Rank=Challenger.png';

export const rankImages = {
  IRON,
  BRONZE,
  SILVER,
  GOLD,
  PLATINUM,
  EMERALD,
  DIAMOND,
  MASTER,
  GRANDMASTER,
  CHALLENGER,
};

export const REQUIRED_RULE = [{ required: true, message: '' }];
//Regex
export const alphaSpace = /^[a-zA-Z ]*$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const specialCharacters = /[!@+';/=#$½%^&£*(§),._?.[":{}\-\]\\|<>]/g;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
export const usernameRegex =
  /^(?!.*[\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])(?!.*(null|undefined))(?!.*(http|https):\/\/[^\s]+).+$/;

//Helper Functions
export const isOrganization = (type) => {
  return type === 'organization';
};

export const hasHash = /#+([a-zA-Z0-9_]+)/gi;

export const truncateText = (text, maxLength = 20) => {
  if (text?.length > maxLength) {
    return truncate(text, maxLength, { ellipsis: '...' });
  } else {
    return text;
  }
};

export const steamIntegrateUrl = (username) => {
  return `https://steamcommunity.com/openid/login?openid.ns=http://specs.openid.net/auth/2.0&openid.claimed_id=http://specs.openid.net/auth/2.0/identifier_select&openid.identity=http://specs.openid.net/auth/2.0/identifier_select&openid.return_to=https://www.game.actor/profile/${username}&openid.realm=https://www.game.actor&openid.mode=checkid_setup`;
};

export const riotIntegrateUrl = () => {
  return 'https://auth.riotgames.com/authorize?client_id=5955bf17-9a62-4996-95d6-28052621724d&redirect_uri=https://www.game.actor/riot/callback&response_type=code&scope=openid+offline_access+cpid';
};

export const steamFeaturedNameHandler = (type) => {
  const translator = useIntl();

  switch (type) {
    case '1':
      return translator.formatMessage({ id: 'comingSoon' });
    case '2':
      return translator.formatMessage({ id: 'specials' });
    case '3':
      return translator.formatMessage({ id: 'topSeller' });
    case '4':
      return translator.formatMessage({ id: 'newReleases' });
    case '5':
      return translator.formatMessage({ id: 'gameActorChosen' });
    default:
      return;
  }
};

export const formatPrice = (value) => {
  if (value === 0) {
    return value;
  }
  const length = value.toString().length;
  const mainPart = value.toString().slice(0, length - 2);
  const decimalPart = value.toString().slice(length - 2);

  return `${mainPart},${decimalPart}`;
};

export const getMostPlayedGenres = (data) => {
  const genrePlaytimeMap = {};

  data?.forEach((item) => {
    item.steamapp?.steamappgenres?.forEach((genreItem) => {
      const genreName = genreItem.genre.name;
      const playtime = item.time_played;
      if (genrePlaytimeMap[genreName]) {
        genrePlaytimeMap[genreName] += playtime;
      } else {
        genrePlaytimeMap[genreName] = playtime;
      }
    });
  });

  const sortedGenres = Object.entries(genrePlaytimeMap)
    .sort(([, a], [, b]) => b - a)
    .map(([genre]) => genre);
  return sortedGenres;
};

export const getGenreIcon = (genre, fontSize = null, marginRight = '.5rem') => {
  switch (genre) {
    case 'Action':
      return (
        <FontAwesomeIcon
          icon={faBolt}
          style={{ color: '#f44336', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Free To Play':
      return (
        <FontAwesomeIcon
          icon={faTag}
          style={{ color: '#4caf50', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Massively Multiplayer':
      return (
        <FontAwesomeIcon
          icon={faUsers}
          style={{ color: '#2196f3', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'RPG':
      return (
        <FontAwesomeIcon
          icon={faDiceD20}
          style={{ color: '#9c27b0', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Adventure':
      return (
        <FontAwesomeIcon
          icon={faCompass}
          style={{ color: '#ff9800', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Strategy':
      return (
        <FontAwesomeIcon
          icon={faChess}
          style={{ color: '#607d8b', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Indie':
      return (
        <FontAwesomeIcon
          icon={faLightbulb}
          style={{ color: '#ffeb3b', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Early Access':
      return (
        <FontAwesomeIcon
          icon={faHourglassStart}
          style={{ color: '#795548', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Simulation':
      return (
        <FontAwesomeIcon
          icon={faCogs}
          style={{ color: '#3f51b5', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Casual':
      return (
        <FontAwesomeIcon
          icon={faSmile}
          style={{ color: '#ffeb3b', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Animation & Modeling':
      return (
        <FontAwesomeIcon
          icon={faFilm}
          style={{ color: '#e91e63', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Design & Illustration':
      return (
        <FontAwesomeIcon
          icon={faPaintBrush}
          style={{ color: '#673ab7', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Photo Editing':
      return (
        <FontAwesomeIcon
          icon={faCamera}
          style={{ color: '#607d8b', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Utilities':
      return (
        <FontAwesomeIcon
          icon={faTools}
          style={{ color: '#9e9e9e', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Racing':
      return (
        <FontAwesomeIcon
          icon={faFlagCheckered}
          style={{ color: '#ff5722', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    case 'Sports':
      return (
        <FontAwesomeIcon
          icon={faFootballBall}
          style={{ color: '#4caf50', marginRight: marginRight, fontSize: fontSize }}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          icon={faGamepad}
          style={{ color: '#f5a623', marginRight: marginRight, fontSize: fontSize }}
        />
      );
  }
};

import {
  Button,
  Checkbox,
  Col,
  Drawer,
  Flex,
  Form,
  Image,
  Radio,
  Row,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import EventDetail from '../components/events/EventDetail';
import {
  CloseOutlined,
  DisconnectOutlined,
  PlusOutlined,
  PoweroffOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React, { useContext, useEffect, useState } from 'react';
import EventCreateForm from '../components/form/events/EventCreateForm';
import useWindowSize from '../hooks/useWindowSize';
import { useGetEvents } from '../hooks/queries/EventQueries';
import { CustomDatePicker, SelectComponent } from '../components/form/filters/Inputs';
import dayjs from 'dayjs';
import useGetOwnerName from '../hooks/useGetOwnerName';
import { useGetEventTags } from '../hooks/queries/UtilityQueries';
import UserEvents from '../components/events/UserEvents';
import { ReactSVG } from 'react-svg';
import GameActorStar from '../assets/svg/game-actor-star.svg';
import { ModalProviderContext } from '../providers/ModalProvider';
import ShareEvent from '../components/events/ShareEvent';
import { useSearchParams } from 'react-router-dom';
import { UtilityContext } from '../providers/UtilityProvider';
import PrimaryCard from '../components/cards/PrimaryCard';
import { truncateText } from '../helpers/constants/constantHelpers';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import BgProfile from '../assets/images/bg-profile.jpg';

const { Text, Paragraph } = Typography;
const initialValues = {
  type: 3,
  only_follow: false,
  min_date: dayjs().startOf('day').toISOString(),
};

const EventList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { openModal, closeModal } = useContext(ModalProviderContext);
  const { data: eventTags } = useGetEventTags();
  const [pageInformation, setPageInformation] = useState({
    page: 1,
    limit: 25,
  });
  const [filterValues, setFilterValues] = useState({
    ...initialValues,
    ...pageInformation,
  });
  const [formOpen, setFormOpen] = useState(false);
  const [isUserEvents, setIsUserEvents] = useState(false);
  const [eventDetailDrawer, setEventDetailDrawer] = useState(null);
  const [shareModal, setShareModal] = useState({
    visibility: false,
    detail: null,
  });

  const [eventSearchForm] = Form.useForm();
  const { data, isLoading } = useGetEvents(filterValues);

  const { width } = useWindowSize();
  const translator = useIntl();

  useEffect(() => {
    let eventIdParam = parseInt(searchParams.get('eventId'));
    if (eventIdParam > 0) {
      setFilterValues({
        ...eventSearchForm.getFieldsValue(),
        ...pageInformation,
        event_id: eventIdParam,
        min_date: null,
      });
      setSearchParams([]);
    } else {
      setFilterValues({
        ...eventSearchForm.getFieldsValue(),
        ...pageInformation,
      });
    }
  }, [pageInformation]);

  useEffect(() => {
    if (shareModal?.visibility) {
      openModal({
        title: translator.formatMessage({ id: 'postEvent' }),
        content: <ShareEvent callBack={closeModal} eventDetail={shareModal?.detail} />,
        footer: null,
        onCancel: closeModal,
      });
    }
  }, [shareModal]);

  const columns = [
    {
      dataIndex: 'event_type',
      key: 'eventStatus',
      width: 47,
      render: (status) =>
        status === 1 ? (
          <Tooltip placement='top' title='Online'>
            <PoweroffOutlined />
          </Tooltip>
        ) : (
          <Tooltip placement='top' title='Offline'>
            <DisconnectOutlined />
          </Tooltip>
        ),
    },
    {
      title: (
        <Text>
          <FormattedMessage id='eventName' />
        </Text>
      ),
      dataIndex: 'name',
      key: 'eventName',
      render: (record) => {
        return <Text> {record} </Text>;
      },
    },
    {
      title: (
        <Text>
          <FormattedMessage id='eventOwner' />
        </Text>
      ),
      key: 'eventOwner',
      render: (record) => {
        return <Text>{useGetOwnerName(record, true)}</Text>;
      },
    },
    {
      title: (
        <Text>
          <FormattedMessage id='eventDate' />{' '}
        </Text>
      ),
      dataIndex: 'event_date',
      key: 'eventDate',
      render: (record) => <Text>{dayjs(record).utc().format('DD.MM.YYYY HH:mm')}</Text>,
    },
    {
      title: (
        <Text>
          <FormattedMessage id='tags' />{' '}
        </Text>
      ),
      dataIndex: 'event_filters',
      key: 'tags',
      render: (tags) => (
        <>
          {tags?.map((tag, index) => {
            return (
              <Text key={index}>
                <Tag color='purple'>{tag.value.toUpperCase()}</Tag>
              </Text>
            );
          })}
        </>
      ),
    },
    {
      title: translator.formatMessage({ id: 'share' }),
      key: 'shareEvent',
      width: 40,
      render: (record) => (
        <Button
          className='rounded-full !block mx-auto p-0 border-none outline-none'
          onClick={() =>
            setShareModal({
              visibility: true,
              detail: record,
            })
          }
          icon={<ReactSVG src={GameActorStar} />}
        />
      ),
    },
  ];

  const searchEventHandler = (formValues) => {
    let eventTags = [];
    if (formValues['event_filters']?.length > 0) {
      for (let option of formValues['event_filters']) {
        eventTags.push({
          filter_key: 'tag',
          value: option,
        });
      }
    }

    if (formValues['min_date']?.length === 0) {
      formValues['min_date'] = null;
    }
    if (formValues['max_date']?.length === 0) {
      formValues['max_date'] = null;
    }

    formValues['event_filters'] = eventTags;

    setFilterValues({
      ...formValues,
      ...pageInformation,
    });
  };

  const getUserEvents = () => {
    setIsUserEvents(true);
  };

  return (
    <Row gutter={[16, 16]} className='px-4 mb-4'>
      <Form
        className='w-full gap-4 flex flex-wrap [&>.form-item]:mb-0 mb-4'
        layout='vertical'
        form={eventSearchForm}
        onFinish={searchEventHandler}
        initialValues={initialValues}
      >
        <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={24}>
          <Form.Item label={translator.formatMessage({ id: 'minDate' })} name='min_date'>
            <CustomDatePicker />
          </Form.Item>
        </Col>
        <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={24}>
          <Form.Item label={translator.formatMessage({ id: 'maxDate' })} name='max_date'>
            <CustomDatePicker />
          </Form.Item>
        </Col>
        <Col xxl={7} xl={7} lg={12} md={8} sm={12} xs={24}>
          <Form.Item name='event_filters' label={translator.formatMessage({ id: 'tags' })}>
            <SelectComponent
              options={eventTags?.data}
              translationId='tags'
              multi={true}
              fieldNames={{
                label: 'name',
                value: 'value',
                key: 'value',
              }}
              filterOption={(input, option) =>
                (option.label?.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
              onChangeRemote={(values) => eventSearchForm.setFieldValue('event_filters', values)}
            />
          </Form.Item>
        </Col>
        <Col xxl={5} xl={5} lg={6} md={8} sm={12} xs={24}>
          <Form.Item label={translator.formatMessage({ id: 'eventType' })} name='type'>
            <Radio.Group>
              <Radio value={1}>Online</Radio>
              <Radio value={2}>Offline</Radio>
              <Radio value={3}>
                <FormattedMessage id='all' />
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col xxl={4} xl={4} lg={6} md={8} sm={12} xs={24}>
          <Form.Item valuePropName='checked' label='&nbsp;' name='only_follow'>
            <Checkbox>
              <FormattedMessage id='onlyFollow' />
            </Checkbox>
          </Form.Item>
        </Col>
        <Col
          className='flex justify-between max-md:flex-col'
          xxl={24}
          xl={24}
          lg={24}
          md={24}
          sm={12}
          xs={24}
        >
          <Button
            className='max-md:mb-2'
            onClick={getUserEvents}
            type='primary'
            icon={<UserOutlined />}
          >
            <Text className='text-inherit'>
              <FormattedMessage id='myEvents' />
            </Text>
          </Button>
          <div className='max-md:flex max-md:flex-col'>
            <Button
              className='mr-4 max-md:mb-2 max-md:w-full max-md:mr-0'
              icon={<PlusOutlined />}
              onClick={() => setFormOpen(true)}
              type='primary'
            >
              <Text className='text-inherit'>
                <FormattedMessage id='createEvent' />
              </Text>
            </Button>
            <Button
              className='max-md:mb-2 max-md:w-full'
              type='primary'
              htmlType='submit'
              icon={<SearchOutlined />}
            >
              <Text className='text-inherit'>
                <FormattedMessage id='search' />
              </Text>
            </Button>
          </div>
        </Col>
      </Form>
      {data?.data?.rows?.map((record) => (
        <Col key={record?.id} xxl={6} xl={6} lg={8} md={12} sm={24}>
          <PrimaryCard
            heightFull={true}
            className='min-h-[321px] h-full [&>.ant-card-body]:h-full [&>.ant-row]:h-full'
          >
            <Flex vertical={true} gap='1rem' className='absolute right-4 top-4 z-[10]'>
              {record.event_type === 1 ? (
                <Tooltip placement='top' title='Online'>
                  <PoweroffOutlined />
                </Tooltip>
              ) : (
                <Tooltip placement='top' title='Offline'>
                  <DisconnectOutlined />
                </Tooltip>
              )}
            </Flex>
            <Col
              className='flex justify-center [&>.ant-image]:flex [&>.ant-image]:justify-center'
              span={24}
            >
              <Image
                className='max-h-[140px] !w-[85%] rounded-[8px]'
                onError={(event) => {
                  event.target.src = BgProfile;
                }}
                src={
                  record.event_attachments.length > 0
                    ? record.event_attachments[0]?.attachmentUrl
                    : BgProfile
                }
              />
            </Col>
            <Col span={24}>
              <Paragraph className='cursor-pointer text-link-color text-center mt-2 !mb-0'>
                {useGetOwnerName(record, true)}
              </Paragraph>
            </Col>
            <Col span={24}>
              <Paragraph className='text-center mt-2 !mb-0'>{record.name}</Paragraph>
            </Col>
            <Col span={24}>
              <Paragraph className='text-center !mb-0'>
                {dayjs(record.event_date).utc().format('DD.MM.YYYY HH:mm')}
              </Paragraph>
            </Col>
            <Col span={24}>
              <Paragraph className='text-center mt-2 !mb-0 [&>p]:!mb-0'>
                {parse(DOMPurify.sanitize(truncateText(record.description, 40)))}
              </Paragraph>
            </Col>
            <Col className='flex justify-between mt-auto' span={24}>
              <Button onClick={() => setEventDetailDrawer(record)} type='primary'>
                <Text className='text-inherit'>
                  <FormattedMessage id='more' />
                </Text>
              </Button>
              <Button
                className='rounded-full p-0 border-none outline-none'
                onClick={() =>
                  setShareModal({
                    visibility: true,
                    detail: record,
                  })
                }
                icon={<ReactSVG src={GameActorStar} />}
              />
            </Col>
          </PrimaryCard>
        </Col>
      ))}
      <Drawer
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={width < 480 ? '100vw' : 640}
        destroyOnClose={true}
        onClose={() => setFormOpen(false)}
        open={formOpen}
        placement={width < 480 ? 'bottom' : 'right'}
        title={translator.formatMessage({ id: 'eventForm' })}
      >
        <EventCreateForm eventTags={eventTags} closer={() => setFormOpen(false)} />
      </Drawer>
      <Drawer
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={width < 480 ? '100vw' : 440}
        destroyOnClose={true}
        onClose={() => setIsUserEvents(false)}
        open={isUserEvents}
        placement={width < 480 ? 'bottom' : 'right'}
        title={translator.formatMessage({ id: 'myEvents' })}
      >
        <UserEvents eventTags={eventTags} />
      </Drawer>
      <Drawer
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        width={width < 480 ? '100vw' : 440}
        destroyOnClose={true}
        onClose={() => setEventDetailDrawer(null)}
        open={!!eventDetailDrawer}
        placement={width < 480 ? 'bottom' : 'right'}
        title={translator.formatMessage({ id: 'eventDetail' })}
      >
        <EventDetail isDrawer={true} detail={eventDetailDrawer} />
      </Drawer>
    </Row>
  );
};
export default EventList;

import { Flex, Image, Skeleton, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useGetOrganizationDetailByIdHandler } from '../../hooks/queries/OrganizationQueries';
import defaultOrganizationPhoto from '../../assets/images/defaultOrganizationPhoto.png';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const OrganizationNewRoleNotification = ({ notification }) => {
  const { data: organizationDetails, isLoading} = useGetOrganizationDetailByIdHandler(
    parseInt(notification.related_parameter),
  );
  const Text = Typography;

  let timeStamp = dateFormatter(notification.created_at);

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <div className='w-full margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/organization/${organizationDetails?.code}`}>
          <Image
            placeholder={<Skeleton avatar active />}
            className='rounded-full object-cover'
            width={40}
            height={40}
            preview={false}
            src={organizationDetails?.logo_url ? organizationDetails?.logo_url : defaultOrganizationPhoto}
          />
        </Link>
        <Link to={`/organization/${organizationDetails?.code}`}>
          <Text>
            {organizationDetails?.name ? organizationDetails?.name : <FormattedMessage id='deletedOrganization' /> } {}
            <FormattedMessage id='organizationNewRoleNotification' />
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default OrganizationNewRoleNotification;

import steamDefaultImage from '../../assets/images/steam_default_image.webp';
import { Flex, Image, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import { formatPrice } from '../../helpers/constants/constantHelpers';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const SteamFeaturedCard = ({
  name,
  discountPercent,
  headerImage,
  finalPrice,
  originalPrice,
  steamApp,
}) => {
  const [imgSrc, setImgSrc] = useState(headerImage);

  const handleError = () => {
    setImgSrc(steamDefaultImage);
  };

  return (
    <Link
      className='text-center'
      target='_blank'
      to={`https://store.steampowered.com/app/${steamApp?.app_id}/`}
    >
      <img
        alt={name}
        loading='lazy'
        className='rounded-[8px] object-cover max-h-[115px]'
        width='100%'
        src={imgSrc}
        onError={handleError}
      />
      <Text className='block text-center mb-2'>{name}</Text>
      <Flex className='mb-2' justify='center' gap='.25rem'>
        <Tag
          className={
            discountPercent > 0 ? 'me-0 cursor-default line-through' : 'me-0 cursor-default'
          }
          color={discountPercent > 0 ? 'red' : 'blue'}
        >
          {String(originalPrice).length === 2 && '0'}
          {formatPrice(originalPrice)} $
        </Tag>
        {discountPercent > 0 && (
          <Tag className='me-0 cursor-default' color='green'>
            {String(finalPrice).length === 2 && '0'}
            {formatPrice(finalPrice)} $
          </Tag>
        )}
      </Flex>
      <Flex justify='center' wrap={true} gap='.25rem'>
        {steamApp?.steamappgenres?.map(({ genre }, index) => (
          <Tag className='cursor-default me-0' color='purple' key={index}>
            {genre?.name}
          </Tag>
        ))}
      </Flex>
    </Link>
  );
};

export default SteamFeaturedCard;

import { Image, Typography, Flex, Button, Progress } from 'antd';
import { SmileOutlined, GifOutlined } from '@ant-design/icons';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UtilityContext } from '../../../../providers/UtilityProvider';
import { useClientError } from '../../../../hooks/useClientError';
import OutsideClickDetector from '../../../hoc/OutsideClickDetector';
import TipTap from '../../../editor/TipTap';
import { usePostFeed } from '../../../../hooks/queries/FeedQueries';
const ShareLolStatics = ({ callBack, tier, rank, wins, losses, points, img, type, winRate }) => {
  const { Text } = Typography;
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [gifPickerDisplay, setGifPickerDisplay] = useState(false);
  const [tipTapContent, setTipTapContent] = useState('');
  const { openNotification } = useContext(UtilityContext);

  const { mutateAsync: postFeed } = usePostFeed();
  const tipTapOnChangeHandler = (value) => {
    setTipTapContent(value);
  };
  const browserLang = navigator.language;
  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };
  const onClientError = useClientError();
  const shareEventHandler = () => {
    let attachmentArray = [];
    let description =
      tipTapContent +
      `${
        browserLang === 'tr'
          ? `<div style="display: flex; align-items: center; justify-content: center; gap: 10px;">
  <img src="${img}" style="width: 84px; height: 84px; border-radius: 8px;" />
  <div style="display: flex; width:100%; flex-direction: column; gap: 3px;">
    <span style="overflow-wrap: break-word;">${
      tier === 'IRON'
        ? 'DEMİR'
        : tier === 'BRONZE'
        ? 'BRONZ'
        : tier === 'SILVER'
        ? 'GÜMÜŞ'
        : tier === 'GOLD'
        ? 'ALTIN'
        : tier === 'PLATINUM'
        ? 'PLATİN'
        : tier === 'EMERALD'
        ? 'ZÜMRÜT'
        : tier === 'DIAMOND'
        ? 'ELMAS'
        : tier === 'MASTER'
        ? 'USTA'
        : tier === 'GRANDMASTER'
        ? 'BÜYÜKUSTA'
        : tier === 'CHALLENGER'
        ? 'ŞAMPİYONLUK'
        : tier
    } ${rank}</span>
    <span style="overflow-wrap: break-word;">Puan: ${points}</span>
    <div style="display:flex; gap: 0px 10px;"><span style="overflow-wrap: break-word;">Zafer: ${wins}</span> <span style="overflow-wrap: break-word;"> Bozgun: ${losses}</span></div>
    <div style="display:flex; width: 100%; align-items: center; gap: 10px;">
      <div style="width: 50%; height: 6px; background-color: rgba(0, 0, 0, 0.3); border-radius: 5px; overflow: hidden;">
        <div
          style="width: ${`${winRate}%`}; height: 100%; background: linear-gradient(to right, rgb(178, 36, 139), rgb(64, 52, 144)); border-radius: 5px 0 0 5px;">
        </div>
      </div>
      <span style="font-size: 13px;">%${winRate} Kazanma oranı</span>
    </div>
  </div>
</div>`
          : `<div style='display: flex; align-items: center; gap: 10px;'>
  <img src='${img}' style='width: 128px; height: 123px; border-radius: 8px;' />
  <div style='display: flex; width:100%; flex-direction: column; gap: 3px;'>
    <span style='overflow-wrap: break-word;'>${tier} ${rank}</span>
    <span style='overflow-wrap: break-word;'>Points: ${points}</span>
     <div style='display:flex; gap: 0px 10px;'><span style='overflow-wrap: break-word;'>Wins: ${wins}</span> <span style='overflow-wrap: break-word;'> Losses: ${losses}</span></div>
    <div style='display:flex; width: 100%; align-items: center; gap: 10px;'>
      <div style='width: 55%; height: 6px; background-color: rgba(0, 0, 0, 0.3); border-radius: 5px; overflow: hidden;'>
        <div
          style='width: ${`${winRate}%`}; height: 100%; background: linear-gradient(to right, rgb(178, 36, 139), rgb(64, 52, 144)); border-radius: 5px 0 0 5px;'>
        </div>
      </div>
      <span style='font-size: 13px;'>%${winRate} Winrate</span>
    </div>
  </div>
</div>`
      }`;
    attachmentArray.push({
      type: 'image',
    });

    postFeed({
      content: description ? description : '',
      attachmentArray: attachmentArray,
    })
      .then((response) => {
        if (response.status === 202) {
          openNotification('eventShared', 'success');
          callBack();
        }
      })
      .catch((err) => onClientError(err));
  };

  return (
    <Flex className='flex-col'>
      <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
        <TipTap
          forwardOnChange={tipTapOnChangeHandler}
          isEmojiVisible={emojiPickerDisplay}
          isGifVisible={gifPickerDisplay}
          emojiViewHandler={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
          gifViewHandler={() => setGifPickerDisplay(!gifPickerDisplay)}
          toolbar={false}
        />
        <Flex className='mt-4 w-full' gap='1rem'>
          <SmileOutlined
            onClick={() => {
              setEmojiPickerDisplay(!emojiPickerDisplay);
              setGifPickerDisplay(false);
            }}
            className='p-2 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary'
          />
          <GifOutlined
            onClick={() => {
              setGifPickerDisplay(!gifPickerDisplay);
              setEmojiPickerDisplay(false);
            }}
            className='p-2 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary'
          />
        </Flex>
        <Flex align='center'>
          <Image width={176} src={img} />
          <Flex className='flex-col w-full'>
            <Text className='font-bold'>
              <FormattedMessage id={tier} /> {rank}
            </Text>
            <Text>
              {type === 'RANKED_SOLO_5x5' ? (
                <FormattedMessage id='soloqueue' />
              ) : (
                <FormattedMessage id='flex' />
              )}{' '}
            </Text>
            <Text>
              <FormattedMessage id='points' />: {points}
            </Text>

            <Flex gap='1rem'>
              <Text>
                <FormattedMessage id='wins' />: {wins}
              </Text>
              <Text>
                <FormattedMessage id='losses' />: {losses}
              </Text>
            </Flex>
            <Flex align='center' gap='0.75rem'>
              <Progress
                className='w-[75%]'
                percent={winRate}
                status='active'
                strokeColor={
                  winRate
                    ? {
                        from: '#b2248b',
                        to: '#403490',
                      }
                    : {
                        from: '#b1b1b1',
                        to: '#2a2a2a',
                      }
                }
              />
              <Text>
                <FormattedMessage id='winrate' />
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex className='mt-4' justify='end'>
          <Button type='primary' onClick={shareEventHandler}>
            <FormattedMessage id='share' />
          </Button>
        </Flex>
      </OutsideClickDetector>
    </Flex>
  );
};

export default ShareLolStatics;

import { createContext, useContext, useEffect, useState } from 'react';
import { notification, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import axiosInstance from '../lib/service/axios';
import { useGetOrganizationCategories } from '../hooks/queries/OrganizationQueries';
import { AuthContext } from './AuthProvider';
import variables from '../style/_variables.module.scss';
export const UtilityContext = createContext({});

const UtilityProvider = ({ children }) => {
  const translator = useIntl();
  const { user } = useContext(AuthContext);
  const [isUserKey, setIsUserKey] = useState();
  const [api, contextHolder] = notification.useNotification();
  const { data: organizationCategories } = useGetOrganizationCategories(user);
  const [theme, setTheme] = useState(JSON.parse(localStorage.getItem('theme')));
  const [gameStaticsTab, setGameStaticsTab] = useState('steam');
  const { Text } = Typography;
  const [matchGenreTab, setMatchGenreTab] = useState('all');
  const changeThemeHandler = () => {
    setTheme(!theme);
  };
  const { white, darkModeTextColor } = variables;
  useEffect(() => {
    if (theme === true) {
      document.getElementsByTagName('html')[0].classList.add('dark');
    } else if (theme === false) {
      document.getElementsByTagName('html')[0].classList.remove('dark');
    }
    localStorage.setItem('theme', JSON.stringify(theme));
  }, [theme]);

  const openNotification = (key, type) => {
    api[type]({
      description: (
        <Text className={theme ? 'text-dark-mode-text-color' : ''}>
          <FormattedMessage id={key} />
        </Text>
      ),
      style: {
        backgroundColor: theme ? '#181818' : white,
        borderRadius: '6px',
        shadow: '6px 6px 6px 6px',
        shadowColor: 'black',
      },
      closeIcon: <CloseOutlined style={{ color: theme ? darkModeTextColor : '' }} />,
    });
  };

  const dynamicAxiosInstance = async (actionName) => {
    let formObject = {
      action: actionName,
    };
    return await axiosInstance.post('securedHandle', formObject);
  };

  return (
    <UtilityContext.Provider
      value={{
        openNotification,
        dynamicAxiosInstance,
        organizationCategories,
        changeThemeHandler,
        theme,
        setIsUserKey,
        isUserKey,
        setMatchGenreTab,
        matchGenreTab,
        setGameStaticsTab,
        gameStaticsTab,
      }}
    >
      {contextHolder}
      {children}
    </UtilityContext.Provider>
  );
};
export default UtilityProvider;

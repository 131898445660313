import React, { useEffect, useState, useRef } from 'react';
import { Row, Col, Typography, Input, Flex, Image, Skeleton } from 'antd';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import PrimaryCard from '../../cards/PrimaryCard';
import { Link } from 'react-router-dom';

const { Text } = Typography;

const LazyImage = ({ src, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const containerRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, []);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div ref={containerRef} className='relative w-[240px] h-auto min-h-[430]'>
      {isLoading && <Skeleton.Image active className='!w-full !h-full rounded-[8px]' />}
      {isVisible && (
        <img
          src={src}
          alt={alt}
          className={`${isLoading ? 'hidden' : 'block rounded-[8px]'}`}
          onLoad={handleImageLoad}
        />
      )}
    </div>
  );
};

const ChampionsWinRate = ({ championDetail }) => {
  const { Title } = Typography;
  const [riotFilterInput, setRiotFilterInput] = useState('');
  const [filteredData, setFilteredData] = useState(championDetail);
  const translator = useIntl();
  const scrollContainerRef = useRef(null);

  useEffect(() => {
    if (riotFilterInput?.length > 0) {
      const filtered = championDetail?.filter((champion) =>
        champion.name.toLowerCase().includes(riotFilterInput.toLowerCase()),
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(championDetail);
    }
  }, [riotFilterInput, championDetail]);

  const scrollHorizontally = (direction) => {
    const scrollAmount = 300;
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  return (
    <PrimaryCard grid={false} className='relative'>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Title level={4}>
            <FormattedMessage id='championMasteryPoints' />
          </Title>
          <Flex justify='space-between' className='w-full mb-4'>
            <Input
              className='max-w-[340px]'
              value={riotFilterInput}
              onChange={(event) => setRiotFilterInput(event.target.value)}
              placeholder={translator.formatMessage({ id: 'search' })}
            />
            <Flex justify='end' gap='1rem'>
              <LeftCircleOutlined
                onClick={() => scrollHorizontally('left')}
                className='text-[18px] cursor-pointer'
              />
              <RightCircleOutlined
                onClick={() => scrollHorizontally('right')}
                className='text-[18px] cursor-pointer'
              />
            </Flex>
          </Flex>
        </Col>
      </Row>
      <Flex
        gap='1rem'
        ref={scrollContainerRef}
        className='overflow-x-auto flex-nowrap no-scrollbar max-w-[85vw]'
      >
        {filteredData?.map((item) => (
          <div className='relative group' key={item?.name}>
            <Link
              target='_blank'
              to={`https://wiki.leagueoflegends.com/en-us/${item?.name.replaceAll(' ', '_')}`}
            >
              <LazyImage
                src={`https://ddragon.leagueoflegends.com/cdn/img/champion/loading/${item?.id}_0.jpg`}
                alt={item?.name}
              />
              <Flex
                vertical={true}
                align='center'
                className='absolute bottom-0 w-full h-full text-center bg-[#000000a3] rounded-b-lg max-h-[24px] group-hover:max-h-full group-hover:rounded-t-lg transition-all duration-300 overflow-hidden'
              >
                <Text className='text-white'>
                  {item?.name} {item?.title}
                </Text>
                <Image
                  className='mt-auto mb-auto'
                  preview={false}
                  width={80}
                  src={`/lol-champion-level/lvl${
                    item?.champion_level > 10 ? 10 : item?.champion_level
                  }.png`}
                />
                <Text className='text-white'>{item?.champion_points.toLocaleString()}</Text>
              </Flex>
            </Link>
          </div>
        ))}
      </Flex>
    </PrimaryCard>
  );
};

export default ChampionsWinRate;

import styles from '../../style/toolbar.module.scss';
import layoutStyles from '../../style/layout.module.scss';
import { faSteam, faTwitch } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Drawer, Flex, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import TwitchDraggable from './TwitchDraggable';
import Draggable from 'react-draggable';
import { useContext, useState } from 'react';
import SteamFeatured from './SteamFeatured';
import { DrawerProviderContext } from '../../providers/DrawerProvider';
import { useIntl } from 'react-intl';
import useWindowSize from '../../hooks/useWindowSize';
import { UtilityContext } from '../../providers/UtilityProvider';
import PrimaryCard from '../cards/PrimaryCard';

const Toolbar = () => {
  const { Title } = Typography;
  const { width } = useWindowSize();
  const [twitchEnabled, setTwitchEnabled] = useState(false);
  const [stemDrawerIsOpen, setStemDrawerIsOpen] = useState(false);
  const translator = useIntl();
  const { theme } = useContext(UtilityContext);

  // const steamFeaturedHandler = () => {
  //   openDrawer({
  //     title: <Text>{translator.formatMessage({ id: 'steamFeatured' })}</Text>,
  //     content: <SteamFeatured />,
  //     drawerProps: {},
  //     footer: null,
  //     width: width > 768 ? 900 : width,
  //   });
  // };

  return (
    <PrimaryCard>
      <Flex className='rounded-lg flex items-center justify-start flex-wrap'>
        <FontAwesomeIcon
          onClick={() => {
            setStemDrawerIsOpen(true);
          }}
          className='ease-in duration-200 rounded-full cursor-pointer text-xl hover:bg-container-color dark:hover:bg-dark-mode-light-border-color p-2'
          icon={faSteam}
        />
        <FontAwesomeIcon
          className='ease-in duration-200 rounded-full cursor-pointer text-xl text-[#a970ff] hover:bg-container-color dark:hover:bg-dark-mode-light-border-color p-2'
          icon={faTwitch}
        />
        {twitchEnabled && (
          <Draggable
            scale={1}
            defaultPosition={{ x: -Math.floor(window.innerWidth / 2), y: 0 }}
            handle='strong'
          >
            <Card style={{ boxShadow: theme && '0 0 6px 0 black' }} className='border-none'>
              <TwitchDraggable callBackHandler={() => setTwitchEnabled(false)} />
            </Card>
          </Draggable>
        )}
        <Drawer
          title={
            <Title className='!my-0' level={5}>
              {translator.formatMessage({ id: 'steamFeatured' })}
            </Title>
          }
          width={width > 768 ? 900 : width}
          open={stemDrawerIsOpen}
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          closable={true}
          onClose={() => {
            setStemDrawerIsOpen(false);
          }}
        >
          <SteamFeatured />
        </Drawer>
      </Flex>
    </PrimaryCard>
  );
};

export default Toolbar;

import { Col, Divider, Flex, Image, Row, Skeleton, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { useUserDetailsWithId, useUserDetailsWithCode } from '../../hooks/queries/UserQueries';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import defaultOrganizationPhoto from '../../assets/images/defaultOrganizationPhoto.png';
import { useGetFeedDetails } from '../../hooks/queries/FeedQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { useGetOrganizationDetailByIdHandler } from '../../hooks/queries/OrganizationQueries';

const MentionNotification = ({ notification }) => {
  const { data: feedDetails } = useGetFeedDetails(parseInt(notification.related_parameter));
  const { data: profileDetails, isLoading: profileLoading } = useUserDetailsWithId(
    parseInt(notification.extra_parameter),
  );
  const { data: organizationInformationByCode, isLoading: organizationLoading } =
    useGetOrganizationDetailByIdHandler(parseInt(notification.extra_parameter));

  const Text = Typography;
  const translator = useIntl();

  let timeStamp = dateFormatter(notification.created_at);

  if (profileLoading && organizationLoading) {
    return <SpinWrapper />;
  }

  const profilePhoto = profileDetails
    ? profileDetails?.userPhotos?.length > 0
      ? profileDetails?.userPhotos[0]?.profile_photo?.length > 0
        ? profileDetails?.userPhotos[0]?.profile_photo
        : defaultProfilePhoto
      : defaultProfilePhoto
    : organizationInformationByCode?.logo_url
    ? organizationInformationByCode?.logo_url
    : defaultOrganizationPhoto;

  const name = profileDetails?.id
    ? `${profileDetails.first_name} ${profileDetails.last_name}`
    : organizationInformationByCode?.name
    ? organizationInformationByCode.name
    : translator.formatMessage({ id: 'unknownUser' });

  return (
    <div className='w-full margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/profile/${profileDetails?.username}`}>
          <Image
            className='rounded-full object-cover'
            width={40}
            height={40}
            preview={false}
            placeholder={<Skeleton avatar active />}
            src={profilePhoto}
          />
        </Link>
        <Link to={`/shared/${feedDetails?.share}`}>
          <Text>
            {name} <FormattedMessage id='mentionNotification' />
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default MentionNotification;

import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Modal,
  Row,
  Tooltip,
  Typography,
  Drawer,
  Image,
} from 'antd';
import styles from '../../../style/profile.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  EditTwoTone,
  EnvironmentOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  UsergroupAddOutlined,
  SettingTwoTone,
  UserSwitchOutlined,
  CloseOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import React, { useContext, useState, Fragment } from 'react';
import OrganizationAboutForm from '../../form/organization/OrganizationAboutForm';
import { countries } from '../../../helpers/HelperJSON';
import {
  useGetOrganizationMembersDetail,
  useGetOrganizationFollowersDetail,
} from '../../../hooks/queries/OrganizationQueries';
import ManageOrganizationMembers from '../../form/organization/ManageOrganizationMembers';
import variables from '../../../style/_variables.module.scss';
import OrganizationGallery from '../../galleries/OrganizationGallery';
import MiniOrganizationGallery from '../../galleries/MiniOrganizationGallery';
import useWindowSize from '../../../hooks/useWindowSize';
import { UtilityContext } from '../../../providers/UtilityProvider';
import defaultProfileImage from '../../../assets/images/defaultProfilePhoto.jpeg';
import { useInView } from 'react-intersection-observer';
import { useGetConnectionDetails, useUserConnections } from '../../../hooks/queries/UserQueries';
import { AuthContext } from '../../../providers/AuthProvider';
import OrganizationMemberModal from './OrganizationMemberModal';
import { DrawerProviderContext } from '../../../providers/DrawerProvider';
import EmptyWrapper from '../../utility/wrappers/EmptyWrapper';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';

const OrganizationAbout = ({
  isOwner,
  isFounder,
  organizationCategories,
  organization,
  followers,
  memberIds,
}) => {
  const [aboutModal, setAboutModal] = useState(false);
  const [followList, setFollowList] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [inviteMemberModal, setInviteMemberModal] = useState(false);
  const { data: memberDetails } = useGetOrganizationMembersDetail(memberIds, organization?.id);
  const { data: followerDetails } = useGetOrganizationFollowersDetail(followers, organization?.id);
  const { user } = useContext(AuthContext);
  const { data: userConntections = [] } = useUserConnections(user?.id);
  const pushToArrayUserConntections = [userConntections];
  const { data: connectionDetails } = useGetConnectionDetails(
    pushToArrayUserConntections,
    user?.id,
  );
  const { openDrawer } = useContext(DrawerProviderContext);
  const { theme } = useContext(UtilityContext);
  const translator = useIntl();
  const { focusedPrimaryColor } = variables;
  const { width } = useWindowSize();
  const { ref } = useInView();
  const { Text, Title } = Typography;

  if (!organizationCategories) {
    return <SpinWrapper />;
  }

  const memberDrawerHandler = () => {
    openDrawer({
      title: translator.formatMessage({ id: 'organizationMembersModal' }),
      content: (
        <ManageOrganizationMembers memberDetails={memberDetails} organization={organization} />
      ),
      drawerProps: {},
      footer: null,
      width: 720,
    });
  };

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          extra={
            isOwner && (
              <Button
                onClick={() => setAboutModal(true)}
                icon={<EditTwoTone twoToneColor={focusedPrimaryColor} />}
                type='link'
              />
            )
          }
          bordered={false}
          className={`${styles['antdCard']} header-solid h-full`}
          title={
            <h6 className='font-semibold m-0'>
              <FormattedMessage id='about' />
            </h6>
          }
          styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <InfoCircleOutlined style={{ color: '#008dec' }} className='mr-4' />
              <FormattedMessage
                id={
                  organizationCategories?.find(
                    (category) => category.id === organization?.categoryId,
                  )?.name
                }
              />
            </Col>
            <Col span={24}>
              <EnvironmentOutlined style={{ color: '#e0493a' }} className='mr-4' />
              {countries?.find((country) => country.value === organization?.country)?.label}
            </Col>
            <Col span={24}>
              <UserSwitchOutlined className='mr-4' />
              <span className='cursor-pointer' onClick={() => setFollowList(true)}>
                {followers?.length} <FormattedMessage id='followerNumber' />
              </span>
            </Col>
            {organization?.website?.length > 0 && (
              <Col span={24}>
                <FontAwesomeIcon icon={faGlobe} style={{ color: '#0f7c10' }} className='mr-4' />
                <Link target='_blank' to={`http://${organization?.website}`}>
                  <Text className='hover:text-link-color duration-300'>
                    {organization?.website}
                  </Text>
                </Link>
              </Col>
            )}
          </Row>
        </Card>
      </Col>
      <Col span={24}>
        <Card
          extra={
            isOwner && (
              <Flex>
                {isFounder && (
                  <Button
                    onClick={() => memberDrawerHandler()}
                    icon={
                      theme ? (
                        <SettingOutlined />
                      ) : (
                        <SettingTwoTone twoToneColor={focusedPrimaryColor} />
                      )
                    }
                  />
                )}
                <Button
                  onClick={() => {
                    setInviteMemberModal(!inviteMemberModal);
                  }}
                  className='border-none'
                  icon={<UsergroupAddOutlined />}
                />
              </Flex>
            )
          }
          bordered={false}
          className={`${styles['antdCard']} header-solid h-full`}
          title={
            <h6 className='font-semibold m-0'>
              <FormattedMessage id='members' />
            </h6>
          }
          styles={{ body: { paddingTop: 0, paddingBottom: 16, overflow: 'hidden' } }}
        >
          {memberDetails?.length > 0 ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <FormattedMessage id='teamMemberParagraph' />
              </Col>
              {memberDetails?.map(({ id, fullname, photoUrl, username, role }) => (
                <Col key={id} span={24}>
                  <Tooltip placement='bottom' title={`${fullname} - ${role}`}>
                    <Link target='_blank' to={`/profile/${username}`}>
                      <Flex align='center' className='w-full gap-x-2'>
                        <Avatar.Group>
                          <Avatar size={45} src={photoUrl ? photoUrl : defaultProfileImage} />
                        </Avatar.Group>
                        <Text>{fullname}</Text>
                      </Flex>
                    </Link>
                  </Tooltip>
                </Col>
              ))}
            </Row>
          ) : (
            <EmptyWrapper />
          )}
        </Card>
      </Col>
      <Col span={24}>
        <Card
          extra={
            <Button
              onClick={() => setGalleryModal(true)}
              icon={<EyeOutlined />}
              type='primary'
              ghost
            >
              <Text className='text-inherit'>
                {width > 760 ? (
                  <FormattedMessage id='seeAll' />
                ) : (
                  <FormattedMessage id='seePhotos' />
                )}
              </Text>
            </Button>
          }
          bordered={false}
          className={`${styles['antdCard']} header-solid h-full`}
          title={
            <h6 className='font-semibold m-0'>
              <FormattedMessage id='photos' />
            </h6>
          }
          styles={{ body: { paddingTop: 0, paddingBottom: 16 } }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              {width > 760 ? <MiniOrganizationGallery organizationId={organization?.id} /> : null}
            </Col>
          </Row>
        </Card>
      </Col>
      <Modal
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'about' })}
        onCancel={() => setAboutModal(false)}
        open={aboutModal}
        destroyOnClose={true}
        centered={true}
        footer={null}
      >
        <OrganizationAboutForm
          organization={organization}
          isFounder={isFounder}
          formCallBackHandler={() => setAboutModal(false)}
        />
      </Modal>
      <Modal
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        title={translator.formatMessage({ id: 'gallery' })}
        onCancel={() => setGalleryModal(false)}
        open={galleryModal}
        destroyOnClose={true}
        centered={true}
        footer={null}
        width={1460}
      >
        <OrganizationGallery organizationId={organization?.id} />
      </Modal>
      <OrganizationMemberModal
        connectionDetails={connectionDetails?.pages[0]?.followDetails}
        setInviteMemberModal={setInviteMemberModal}
        inviteMemberModal={inviteMemberModal}
      />
      <Drawer
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300' />
        }
        width={320}
        destroyOnClose={true}
        onClose={() => setFollowList(false)}
        open={followList}
        placement={width < 480 ? 'bottom' : 'right'}
      >
        <div>
          <Title level={5}>
            <FormattedMessage id='followersPlural' />
          </Title>
          <Fragment key='followers'>
            {followerDetails?.map((user, index) => (
              <Link target='_blank' key={user.id} to={`/profile/${user.username}`}>
                <Flex
                  ref={index + 1 === followerDetails.length ? ref : null}
                  gap='1rem'
                  className='mt-4'
                >
                  <Image
                    src={user.photoUrl ? user.photoUrl : defaultProfileImage}
                    preview={true}
                    width={55}
                    height={45}
                    className='rounded-[8px] object-cover'
                  />
                  <div>
                    <Text>{user.username}</Text>
                    <Text className='text-xs font-normal !text-[#547793] block'>
                      {user.fullname}
                    </Text>
                  </div>
                </Flex>
              </Link>
            ))}
          </Fragment>
        </div>
      </Drawer>
    </Row>
  );
};

export default OrganizationAbout;

import { Flex, Image, Skeleton, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { useUserDetailsWithId, useUserDetailsWithCode } from '../../hooks/queries/UserQueries';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const FollowNotification = ({ notification }) => {
  const { data: profileDetails, isLoading } = useUserDetailsWithId(parseInt(notification.related_parameter));

  const Text = Typography;
  const translator = useIntl();

  let timeStamp = dateFormatter(notification.created_at);

  if (isLoading) {
    return <SpinWrapper />;
  }

  const name = profileDetails?.id
    ? `${profileDetails.first_name} ${profileDetails.last_name}`
    : translator.formatMessage({ id: 'unknownUser' });

  return (
    <div key={notification.id} className='w-full border-0 margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/profile/${profileDetails?.username}`}>
          <Image
            active
            className='rounded-full object-cover'
            width={40}
            height={40}
            preview={false}
            placeholder={<Skeleton avatar active />}
            src={
              profileDetails?.userPhotos?.length > 0
                ? profileDetails?.userPhotos[0]?.profile_photo?.length > 0
                  ? profileDetails?.userPhotos[0]?.profile_photo
                  : defaultProfilePhoto
                : defaultProfilePhoto
            }
          />
        </Link>
        <Link to={`/profile/${profileDetails?.username}`}>
          <Text>
            {name}{' '}
            <FormattedMessage id='followNotification' />
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default FollowNotification;

import React, { useContext, useEffect, useState } from 'react';
import { UtilityContext } from '../providers/UtilityProvider';
import { Typography } from 'antd';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import { useRiotSignOn } from '../hooks/queries/IntegrationQueries';
import { AuthContext } from '../providers/AuthProvider';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

const RiotIntegration = () => {
  const { Title } = Typography;
  const { openNotification } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const { mutateAsync: riotSignOn } = useRiotSignOn();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (user) {
      const code = searchParams.get('code');

      if (code?.length > 0) {
        riotSignOn(code)
          .then((response) => {
            if (response.status === 202) {
              openNotification('userActivatedCompleted', 'success');
              location.replace(`/profile/${user.username}`);
            } else {
              openNotification('unknownError', 'error');
            }
          })
          .catch(() => openNotification('unknownError', 'error'))
          .finally(() => setIsLoading(false));
      }
    }
  }, [user]);

  return (
    <div className='w-full h-[100vh] flex justify-center items-center'>
      <Title level={3}>
        {!isLoading && <FormattedMessage id='userActivated' />}
        <SpinWrapper />
      </Title>
    </div>
  );
};

export default RiotIntegration;

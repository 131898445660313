import React, { useContext } from 'react';
import PrimaryCard from '../../cards/PrimaryCard';
import { Image, Typography, Flex, Button } from 'antd';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import {
  UserAddOutlined,
  PlusOutlined,
  MinusOutlined,
  UserDeleteOutlined,
  UserSwitchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import {
  useAnswerRequest,
  useCheckConnectionRequest,
  useConnectionRequest,
  useFollowers,
  useFollowUser,
  useRemoveConnection,
  useUserConnections,
} from '../../../hooks/queries/UserQueries';

import { AuthContext } from '../../../providers/AuthProvider';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { FormattedMessage } from 'react-intl';

const UserCard = ({ id, img, fullName, userName }) => {
  const { user } = useContext(AuthContext);
  const { data: userFollowers = [], isLoading: followersLoading } = useFollowers(id);
  const { openNotification } = useContext(UtilityContext);
  const { mutateAsync: followUser } = useFollowUser(id);
  const { data: userConnections = [], isLoading: connectionsLoading } = useUserConnections(
    user?.id,
  );
  const { mutateAsync: sendConnection } = useConnectionRequest(id);
  const { data: connectionStatus = [] } = useCheckConnectionRequest(id);
  const { mutateAsync: removeConnection } = useRemoveConnection();
  const { mutateAsync: answerRequest } = useAnswerRequest();
  const { Text } = Typography;
  const followHandler = (id) => {
    let isFollowing = userFollowers.includes(user?.id);
    followUser({
      userId: id,
      follower_user_id: user?.id,
    }).then((response) => {
      if (response.status === 202) {
        if (isFollowing) {
          return openNotification('userFollowStopped', 'success');
        }
        return openNotification('userFollowed', 'success');
      }
    });
  };

  const connectionHandler = () => {
    if (userConnections.includes(id)) {
      removeConnection(id).then((response) => {
        return response.status === 202
          ? openNotification('connectionRemoved', 'success')
          : openNotification('unknownError', 'error');
      });
    } else if (connectionStatus.length > 0 && connectionStatus[0]?.id === id) {
      answerRequest({
        request_id: connectionStatus[0].requestId,
        status: 2,
      }).then((response) => {
        return response.status === 202
          ? openNotification('networkRequestCancelled', 'success')
          : openNotification('unknownError', 'error');
      });
    } else if (connectionStatus.length > 0 && connectionStatus[0]?.id !== id) {
      answerRequest({
        request_id: connectionStatus[0].requestId,
        status: 1,
      }).then((response) => {
        return response.status === 202
          ? openNotification('connectionApproved', 'success')
          : openNotification('unknownError', 'error');
      });
    } else {
      sendConnection({
        userId: id,
        follower_user_id: user?.id,
      }).then((response) => {
        return response.status === 202
          ? openNotification('connectionRequestSent', 'success')
          : openNotification('unknownError', 'error');
      });
    }
  };

  return (
    <PrimaryCard>
      <Link className='w-full' to={`/profile/${userName}`}>
        <Flex className='flex-col w-full' gap='0.75rem' justify='center' align='center'>
          <Image
            className='rounded-full object-cover'
            preview={false}
            width={58}
            height={58}
            src={img ? img : defaultProfilePhoto}
          />
          <Flex align='center' gap='0.25rem'>
            <UserOutlined className='text-sm' />{' '}
            <Text className='text-xs'>
              {userFollowers?.length} <FormattedMessage id='numberOfFollowers' />
            </Text>
          </Flex>
          <Flex className='flex-col w-full' justify='center' align='center'>
            <Text>{fullName}</Text>
            <Text className='text-sm dark:!text-dark-mode-primary-color'>{userName}</Text>
          </Flex>
        </Flex>
      </Link>
      {user?.id !== id && (
        <Flex className='w-full mt-2' align='center' justify='center' gap='0.75rem'>
          <Flex onClick={connectionHandler}>
            {userConnections.includes(id) ? (
              <Button loading={connectionsLoading} icon={<UserDeleteOutlined />} />
            ) : connectionStatus[0]?.id === id ? (
              <Button loading={connectionsLoading} icon={<UserSwitchOutlined />} />
            ) : (
              <Button loading={connectionsLoading} icon={<UserAddOutlined />} />
            )}
          </Flex>

          <Flex
            onClick={() => {
              followHandler(id);
            }}
          >
            {userFollowers.includes(user?.id) ? (
              <Button loading={followersLoading} icon={<MinusOutlined />} />
            ) : (
              <Button loading={followersLoading} icon={<PlusOutlined />} />
            )}
          </Flex>
        </Flex>
      )}
    </PrimaryCard>
  );
};

export default UserCard;

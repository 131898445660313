import { FormattedMessage, useIntl } from 'react-intl';
import { Typography, Flex, Image, Tooltip, Button } from 'antd';
import { useGetLolStats } from '../../../hooks/queries/IntegrationQueries';
import RiotGamesLibrary from '../../integrations/riot/RiotGamesLibrary';
import { useUserDetailsWithCode } from '../../../hooks/queries/UserQueries';
import { useContext, useState, useEffect } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { useParams } from 'react-router-dom';
import IRON from '../../../assets/images/Ranked Emblems Latest/Rank=Iron.png';
import BRONZE from '../../../assets/images/Ranked Emblems Latest/Rank=Bronze.png';
import SILVER from '../../../assets/images/Ranked Emblems Latest/Rank=Silver.png';
import GOLD from '../../../assets/images/Ranked Emblems Latest/Rank=Gold.png';
import PLATINUM from '../../../assets/images/Ranked Emblems Latest/Rank=Platinum.png';
import EMERALD from '../../../assets/images/Ranked Emblems Latest/Rank=Emerald.png';
import DIAMOND from '../../../assets/images/Ranked Emblems Latest/Rank=Diamond.png';
import MASTER from '../../../assets/images/Ranked Emblems Latest/Rank=Master.png';
import GRANDMASTER from '../../../assets/images/Ranked Emblems Latest/Rank=Grandmaster.png';
import CHALLENGER from '../../../assets/images/Ranked Emblems Latest/Rank=Challenger.png';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';

const { Text } = Typography;

const RiotGamesIntegrationTab = ({ isOwner, showMoreSteam }) => {
  const rankImages = {
    IRON,
    BRONZE,
    SILVER,
    GOLD,
    PLATINUM,
    EMERALD,
    DIAMOND,
    MASTER,
    GRANDMASTER,
    CHALLENGER,
  };
  const translator = useIntl();

  const { code } = useParams();
  const { data: profileDetailsWithCode } = useUserDetailsWithCode(code);
  const { setGameStaticsTab } = useContext(UtilityContext);
  const { data: lolStats, isLoading: lolLoading } = useGetLolStats(profileDetailsWithCode?.id);

  const [championData, setChampionData] = useState([]);

  const [championDetail, setChampionDetail] = useState([]);

  const championDataValues = Object.values(championData.data || {});

  const userRiotGamesId = lolStats?.summoner?.puuid;

  const soloQMathces = lolStats?.ranked_stats?.reduce((total, item) => {
    if (item?.queue_type === 'RANKED_SOLO_5x5') {
      return total + item.wins + item.losses;
    }
    return total;
  }, 0);

  const flexMatches = lolStats?.ranked_stats?.reduce((total, item) => {
    if (item?.queue_type === 'RANKED_FLEX_SR') {
      return total + item?.wins + item?.losses;
    }
    return total;
  }, 0);

  const soloQWin = lolStats?.ranked_stats?.reduce((total, item) => {
    if (item?.queue_type === 'RANKED_SOLO_5x5') {
      return total + item.wins;
    }
    return total;
  }, 0);
  const flexQWin = lolStats?.ranked_stats?.reduce((total, item) => {
    if (item?.queue_type === 'RANKED_FLEX_SR') {
      return total + item.wins;
    }
    return total;
  }, 0);

  const soloQWinrate = ((100 * soloQWin) / soloQMathces).toFixed(1);
  const flexWinrate = ((100 * flexQWin) / flexMatches).toFixed(1);

  useEffect(() => {
    fetch('/champion.json')
      .then((response) => response.json())
      .then((data) => setChampionData(data));
  }, []);

  useEffect(() => {
    const newData = lolStats?.champion_stats?.map((item) => {
      const compareItem = championDataValues?.find((champ) => champ.key == item.champion_id);
      if (compareItem) {
        return { ...item, ...compareItem };
      }
      return null;
    });

    setChampionDetail(newData);
  }, [lolStats?.champion_stats, championData, profileDetailsWithCode]);

  const renderNoRiotIntegrated = () => (
    <Text>
      <FormattedMessage id='noRiotGamesIntegrated' />
    </Text>
  );

  const renderRiotLeaguesNew = () => (
    <Flex vertical={true} gap='1rem'>
      <Button
        className='block ml-auto'
        type='primary'
        onClick={() => {
          showMoreSteam();
          setGameStaticsTab('leagueOfLegend');
        }}
      >
        <FormattedMessage id='more' />
      </Button>
      <Flex gap='1rem' justify='center' className='flex-wrap'>
        {lolStats?.ranked_stats?.map((item) => {
          if (item.queue_type === 'RANKED_SOLO_5x5') {
            return (
              <div key={item.queue_type}>
                <Flex align='center'>
                  <Image
                    width={100}
                    preview={false}
                    src={rankImages[item?.tier] || rankImages['IRON']}
                  />
                  <Flex vertical={true}>
                    <Flex gap='0.25rem'>
                      <Text className='text-md'>
                        <FormattedMessage id={item?.tier} />
                      </Text>
                      <Text className='text-md'>{item?.rank}</Text>
                    </Flex>
                    <Text className='text-sm text-gray'>Soloque</Text>
                    <Tooltip title={translator.formatMessage({ id: 'winRate' })}>
                      <Text className='text-sm'>
                        <FormattedMessage id='wr' />: {soloQWinrate}%
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>
              </div>
            );
          } else {
            return (
              <div key={item.queue_type}>
                <Flex align='center'>
                  <Image
                    width={100}
                    preview={false}
                    src={rankImages[item?.tier] || rankImages['IRON']}
                  />
                  <Flex vertical={true}>
                    <Flex gap='0.25rem'>
                      <Text className='text-md'>
                        <FormattedMessage id={item?.tier} />
                      </Text>
                      <Text className='text-md'>{item?.rank}</Text>
                    </Flex>
                    <Text className='text-sm text-gray'>Flex</Text>
                    <Tooltip title={translator.formatMessage({ id: 'winRate' })}>
                      <Text className='text-sm'>
                        <FormattedMessage id='wr' />: {flexWinrate}%
                      </Text>
                    </Tooltip>
                  </Flex>
                </Flex>
              </div>
            );
          }
        })}
      </Flex>
      <Flex className='flex-wrap' justify='center' gap='1rem'>
        {championDetail?.slice(0, 4)?.map((item) => (
          <div className='group relative' key={item?.champion_id}>
            <div className='absolute left-1 top-[-2px] z-10'>
              <Image
                preview={false}
                width={40}
                src={`/lol-champion-level/lvl${
                  item?.champion_level > 10 ? 10 : item?.champion_level
                }.png`}
              />
            </div>
            <Flex
              vertical={true}
              align='center'
              justify='center'
              className='absolute z-20 left-0 bottom-0 right-0 top-0 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in bg-[#0000008f] rounded-[10px]'
            >
              <Text className='text-white'>{item?.name}</Text>
              <Text className='text-white'>{item?.champion_points.toLocaleString()}</Text>
            </Flex>
            <Image
              width={90}
              height={90}
              className='rounded-[10px] object-cover'
              preview={false}
              src={`https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${item?.id}_0.jpg`}
            />
          </div>
        ))}
      </Flex>
    </Flex>
  );

  return (
    <Flex className='flex-col' gap='1rem'>
      {isOwner ? <RiotGamesLibrary lolStats={lolStats} userRiotGamesId={userRiotGamesId} /> : ''}
      {lolLoading ? <SpinWrapper /> : !lolStats ? renderNoRiotIntegrated() : renderRiotLeaguesNew()}
    </Flex>
  );
};

export default RiotGamesIntegrationTab;

import { Button, Flex, Image, Progress, Tag, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useContext } from 'react';
import { rankImages } from '../../../helpers/constants/constantHelpers';
import { ShareAltOutlined } from '@ant-design/icons';
import ShareLolStatics from './share-lol-statics/ShareLolStatics';
import { ModalProviderContext } from '../../../providers/ModalProvider';

const { Text } = Typography;

const LeagueRanks = ({ title, rankedStats, winRate }) => {
  const translator = useIntl();
  const { openModal, closeModal } = useContext(ModalProviderContext);

  const shareLeagueHandler = () => {
    openModal({
      title: translator.formatMessage({ id: 'shareAchievement' }),
      content: (
        <ShareLolStatics
          callBack={closeModal}
          tier={rankedStats?.tier}
          rank={rankedStats?.rank}
          wins={rankedStats?.wins}
          losses={rankedStats?.losses}
          points={rankedStats?.league_points}
          img={rankImages[rankedStats?.tier]}
          type={rankedStats?.type}
          winRate={winRate}
        />
      ),
      footer: null,
      onCancel: closeModal,
    });
  };

  return (
    <Flex className='w-full mb-4' gap='1rem'>
      <Image
        className='min-w-[90px]'
        width={90}
        preview={false}
        src={rankImages[rankedStats?.tier] || rankImages['IRON']}
      />
      <Flex id='winRate' className='flex-col w-full' gap='.25rem'>
        <Flex justify='space-between' align='center'>
          <Text>
            {title === 'solo' ? (
              <FormattedMessage id='rankedSolo' />
            ) : (
              <FormattedMessage id='rankedFlex' />
            )}
          </Text>
          <ShareAltOutlined
            onClick={shareLeagueHandler}
            className='bg-container-color hover:bg-primary text-primary hover:text-white p-1.5 rounded-full transition-all duration-300 ease-in cursor-pointer text-md'
          />
        </Flex>
        <Flex gap='0.75rem'>
          <Text strong={true}>
            <FormattedMessage id={rankedStats?.tier ? rankedStats?.tier : 'iron'} />{' '}
            {rankedStats?.rank}
          </Text>
          <Text className='ml-1' strong={true}>
            ({rankedStats?.league_points} LP)
          </Text>
        </Flex>
        <Flex className='mb-2'>
          <Tag color='green'>
            {rankedStats?.wins} {translator.formatMessage({ id: 'wins' })[0]}
          </Tag>
          <Tag color='red'>
            {rankedStats?.losses} {translator.formatMessage({ id: 'losses' })[0]}
          </Tag>
          <Text className='ml-1'>({winRate} %)</Text>
        </Flex>
        <Progress
          percent={winRate}
          showInfo={false}
          status='active'
          strokeColor={{
            from: '#5db224',
            to: '#403490',
          }}
        />
      </Flex>
    </Flex>
  );
};

export default LeagueRanks;

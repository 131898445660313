import axiosInstance from '../lib/service/axios';

export const saveSteamId = async (steamId) => {
  let formObject = {
    action: 'steamAuth',
    steamAuthPayload: {
      steam_id: steamId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const syncSteam = async () => {
  let formObject = {
    action: 'syncSteamLibrary',
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const syncLoL = async (puuid, server, userId) => {
  let formObject = {
    action: 'riotAuth',
    riotAuthPayload: {
      puuid: puuid,
      server: server,
      userId: userId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getSteamLibrary = async (userId) => {
  let formObject = {
    action: 'getSteamLibrary',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const syncAchievements = async (steamAppId, userId) => {
  let formObject = {
    action: 'syncAchievements',
    syncAchievementsPayload: {
      steamapp_id: steamAppId?.toString(),
      userId: userId,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getGameAchievements = async (steamAppId, userId) => {
  let formObject = {
    action: 'getUserAchievements',
    syncAchievementsPayload: {
      steamapp_id: steamAppId?.toString(),
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getFeaturedSteam = async () => {
  let formObject = {
    action: 'getFeaturedSteam',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getSteamLastActivity = async (userId) => {
  let formObject = {
    action: 'getLastActivity',
    user: {
      userId: userId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUserSteamGenres = async (userId) => {
  const formObject = {
    action: 'getUserGenres',
    user: {
      userId: userId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getMutualSteamGames = async (userId) => {
  let formObject = {
    action: 'getMutualLibrary',
    getMutualLibrary: {
      requestedId: userId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getLolStatsHandler = async (userId) => {
  let formObject = {
    action: 'getLolStats',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const riotSignOnHandler = async (code) => {
  let formObject = {
    action: 'riotSignOn',
    riotAuthPayload: {
      code: code,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

import { Card, Flex, Tag, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import React, { useState, useRef, useEffect } from 'react';
import steamDefaultImage from '../../assets/images/steam_default_image.webp';
import { getHoursAndMinutes } from '../../helpers/utils/DateFormatter';
import LazyImage from '../utility/LazyImage';

const { Text } = Typography;

const GameCard = ({
  gameTime,
  url,
  genres,
  more = false,
  showMore,
  showAchievements,
  mutual = false,
  integrationTab,
}) => {
  const [imgSrc, setImgSrc] = useState(url);

  const handleError = () => {
    setImgSrc(steamDefaultImage);
  };

  if (more) {
    return (
      <Card className='cursor-pointer [&>*]:!py-0' onClick={showMore}>
        <div className='flex justify-center items-center h-full'>
          <Text>
            <FormattedMessage id='more' />
          </Text>
        </div>
      </Card>
    );
  }

  return (
    <div onClick={showAchievements} className='relative group'>
      <LazyImage
        src={imgSrc}
        alt='Game'
        className='select-none cursor-pointer rounded-[8px] object-cover max-h-[160px] w-full'
        onError={handleError}
      />

      <div className='flex flex-col absolute bottom-0 w-full h-full text-center bg-[#000000a3] rounded-b-lg max-h-[24px] group-hover:max-h-full group-hover:rounded-t-lg transition-all duration-300 overflow-hidden'>
        {gameTime ? (
          <Text className='text-white h-[24px]'>
            {mutual ? (
              <FormattedMessage id='gameTimeTogether' />
            ) : (
              <FormattedMessage id='gameTime' />
            )}
            : {getHoursAndMinutes(gameTime)}
          </Text>
        ) : (
          <Text className='text-white h-[24px]'>&nbsp;</Text>
        )}
        <Flex justify='center' wrap={true} gap='.5rem' className='mt-2 overflow-auto no-scrollbar'>
          {genres?.map(({ genre }, index) => (
            <Tag className='cursor-default me-0' color='purple' key={index}>
              {genre.name}
            </Tag>
          ))}
        </Flex>
      </div>
    </div>
  );
};

export default GameCard;

import styles from '../style/profile.module.scss';
import BgProfile from '../assets/images/bg-profile.jpg';
import { Button, Card, Col, Flex, Image, Row, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { AuthContext } from '../providers/AuthProvider';
import { UtilityContext } from '../providers/UtilityProvider';
import { useParams } from 'react-router-dom';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import {
  useGetOrganizationDetailByCodeHandler,
  useGetOrganizationFollowers,
  useGetOrganizationMembers,
  useGetOrganizationFeedHandler,
  useFollowOrganization,
} from '../hooks/queries/OrganizationQueries';
import CreateOrganizationPost from '../components/form/organization/CreateOrganizationPost';
import PostCard from '../components/post/PostCard';
import { Fragment, useContext, useEffect, useState } from 'react';
import OrganizationAbout from '../components/profile/organization/OrganizationAbout';
import { useInView } from 'react-intersection-observer';
import Repost from '../components/post/Repost';
import EmptyWrapper from '../components/utility/wrappers/EmptyWrapper';
import { truncateText } from '../helpers/constants/constantHelpers';
import useWindowSize from '../hooks/useWindowSize';

const { Text, Title } = Typography;

const OrganizationPage = () => {
  const { ref, inView } = useInView();
  const { organizationCategories, openNotification } = useContext(UtilityContext);
  const { userOwnedPages, user } = useContext(AuthContext);
  const { code } = useParams();
  const { data: organization } = useGetOrganizationDetailByCodeHandler(code);
  const { data: followers } = useGetOrganizationFollowers(organization?.id);

  const { data: memberIds } = useGetOrganizationMembers(organization?.id);
  const { data: feed = [], fetchNextPage } = useGetOrganizationFeedHandler(organization?.id);
  const { mutateAsync: followOrganization } = useFollowOrganization();
  const [isOwner, setIsOwner] = useState(null);
  const translator = useIntl();
  const { width } = useWindowSize();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    setIsOwner(userOwnedPages?.filter((page) => page.code === code).length > 0);
  }, [userOwnedPages, organization]);

  if (isOwner === null) {
    return <SpinWrapper />;
  }

  if (!organization?.name) {
    return <EmptyWrapper description={translator.formatMessage({ id: 'noOrganizationMatched' })} />;
  }

  const followHandler = () => {
    let isFollowing = followers.includes(user?.id);
    followOrganization({
      organization_id: organization?.id,
      userId: user?.id,
    }).then((response) => {
      if (response?.status === 202) {
        if (isFollowing) {
          return openNotification('organizationFollowStopped', 'success');
        }
        return openNotification('organizationFollowed', 'success');
      }
    });
  };

  let bannerUrl = organization?.banner_url ? organization?.banner_url : BgProfile;

  return (
    <div className={styles['profileRow']}>
      <Col span={24} md={24} className='col-info flex profile-nav-bg'>
        <Image
          preview={true}
          src={bannerUrl}
          className='rounded-[8px] object-cover w-full'
          height={300}
          width='100%'
        />
      </Col>
      <Card
        className={`${styles['antdCard']} card-profile-head`}
        styles={{ body: { display: 'none' } }}
        title={
          <Row justify='space-between' align='middle' gutter={[24, 0]}>
            <Col span={24} md={20} className='col-info'>
              <Flex gap='1rem'>
                <Image
                  className='rounded-[8px] object-cover min-w-[110px]'
                  width={110}
                  height={110}
                  preview={true}
                  src={organization?.logo_url}
                />
                <div className='avatar-info'>
                  <Title level={3} className='font-semibold m-0 !mb-0'>
                    {width > 760 ? organization?.name : truncateText(organization?.name, 12)}{' '}
                  </Title>
                  <Text className='whitespace-normal '> {organization?.description}</Text>
                </div>
              </Flex>
            </Col>
            <Col
              span={24}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {!isOwner && (
                <Button
                  type='primary'
                  onClick={followHandler}
                  icon={followers?.includes(user?.id) ? <MinusOutlined /> : <PlusOutlined />}
                >
                  <Text className='text-inherit'>
                    {followers?.includes(user?.id) ? (
                      <FormattedMessage id='unFollow' />
                    ) : (
                      <FormattedMessage id='follow' />
                    )}
                  </Text>
                </Button>
              )}
            </Col>
          </Row>
        }
      />

      <Row gutter={[16, 16]}>
        <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
          <OrganizationAbout
            memberIds={memberIds}
            followers={followers}
            isOwner={isOwner}
            isFounder={organization?.founder_id === user?.id}
            organization={organization}
            organizationCategories={organizationCategories}
          />
        </Col>
        <Col xxl={16} xl={16} lg={16} md={16} sm={24} xs={24}>
          <>
            {isOwner && (
              <CreateOrganizationPost
                organizationId={organization?.id}
                organizationImage={organization?.logo_url}
                organizationName={organization?.name}
                organizationCategory={
                  <FormattedMessage
                    id={
                      organizationCategories?.find(
                        (category) => category.id === organization?.categoryId,
                      )?.name
                    }
                  />
                }
              />
            )}
          </>
          {feed?.pages?.map((page, pageIndex) => (
            <Fragment key={pageIndex}>
              {page?.rows?.map((post, index) => {
                return (
                  <div
                    ref={index + 1 === page.rows.length ? ref : null}
                    className='mt-[1rem]'
                    key={index}
                  >
                    {post.relatedFeedId > 0 ? (
                      <Repost
                        postDetail={post}
                        share={post?.share}
                        postId={post.id}
                        boosted={false}
                      />
                    ) : (
                      <PostCard
                        postDetail={post}
                        share={post?.share}
                        postId={post.id}
                        boosted={false}
                      />
                    )}
                  </div>
                );
              })}
            </Fragment>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationPage;

import React from 'react';
import PrimaryCard from '../../cards/PrimaryCard';
import { Typography, Col, Flex, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';
const Navigation = () => {
  const { Title, Text } = Typography;
  const style =
    'transition-all duration-300 transition-transform transform hover:translate-x-2 cursor-pointer p-1 rounded-[8px] hover:bg-container-color dark:hover:bg-dark-mode-light-border-color';
  return (
    <PrimaryCard>
      <Col span={24}>
        <Title level={4}>
          <FormattedMessage id='inThisPage' />
        </Title>
      </Col>
      <Divider className='my-4' />
      <Col span={24}>
        <Flex className='flex-col w-full'>
          <a href='#userList' className={style}>
            <Text>
              <FormattedMessage id='users' />
            </Text>
          </a>
          <a href='#organizationList' className={style}>
            <Text>
              <FormattedMessage id='organizations' />
            </Text>
          </a>
          <a href='#postList' className={style}>
            <Text>
              <FormattedMessage id='posts' />
            </Text>
          </a>
        </Flex>
      </Col>
    </PrimaryCard>
  );
};

export default Navigation;

import React, { Fragment, useEffect } from 'react';
import { useGetFeedHandler } from '../../../hooks/queries/FeedQueries';
import { Col, Typography, Flex, Empty } from 'antd';
import Repost from '../../post/Repost';
import PostCard from '../../post/PostCard';
import { useInView } from 'react-intersection-observer';
import { FormattedMessage } from 'react-intl';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';

const PostList = ({ hashtag }) => {
  const {
    data: feed,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetFeedHandler(null, hashtag, 2);

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const { Title } = Typography;

  if (feed?.pages[0].rows?.length === 0) {
    return (
      <div className='flex flex-col gap-1 mt-4'>
        <Empty description={<FormattedMessage id='itsAllEmpty' />} />
      </div>
    );
  }

  return (
    <div className='flex flex-col gap-1'>
      <Title className='px-2' level={3}>
        <FormattedMessage id='posts' />
      </Title>
      <Flex vertical={true} gap='1rem'>
        {feed?.pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {page.rows.map((post, index) => {
              const isLastItem =
                pageIndex === feed.pages?.length - 1 && index === page.rows?.length - 1;
              return (
                <Col ref={isLastItem ? ref : null} key={`${pageIndex}-${index}`} span={24}>
                  {post.relatedFeedId > 0 ? (
                    <Repost postDetail={post} postId={post.id} boosted={false} />
                  ) : (
                    <PostCard
                      postDetail={post}
                      share={post?.share}
                      postId={post.id}
                      boosted={false}
                    />
                  )}
                </Col>
              );
            })}
          </Fragment>
        ))}
      </Flex>
      {isFetchingNextPage && <SpinWrapper />}
    </div>
  );
};

export default PostList;
